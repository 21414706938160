
import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../common/appApiCallHook'


export function useOrderApi() {

    const callApi = useApiCall()

   
    const listOrder = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ORDER.ORDER_LIST, data, onSuccess, onError);
    }

    const orderDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ORDER.ORDER_DETAILS, data, onSuccess, onError);
    }
    
    

    

    return {
        callListOrder:listOrder,
        callOrderDetails:orderDetails
    }
}