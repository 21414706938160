import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useCollectionApi } from "src/_common/hooks/actions/collection/collectionApiHook";
import { Tab, Tabs } from "react-bootstrap";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import DateInput from "src/_common/components/form-elements/datepicker/dateInput";
import GenesisUser from "src/containers/genesis/genesis";
import { CONTRACT_TYPE, NETWORK_TYPE, OptionValue } from "src/_common/interfaces/common";
import PayoutTab from "src/containers/payoutTab/payoutTab";

import FormTexAreatInput from "src/_common/components/form-elements/texareaInput";
import TinyMceEditor from "src/_common/components/form-elements/tinyMceEditor";
import {
  useCollectionListSelector, useGenesisDetailsSelector
} from "src/_common/hooks/selectors/eventSelector";
import moment from 'moment';
import Product from "src/containers/product";
import SettingsUser from "src/containers/settings/settings";



interface CollectionFormValues {
  name: string;
  url: string;
  description: string;
  slug: string;
  className: string;
  royalty: number;
  revealDate: any;
  payoutWalletAddress: string;
  autoMint: boolean;
  isPublished: boolean;
  contractCollectionType: OptionValue;
  network: OptionValue;
  releaseDate: any;
  categories: OptionValue;
  privateWalletAddress: string;
  shortName: string
  storeFee:number
  storeFeeSecondary:string
  about: string
}

const CollectionSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9 ]+$/, 'Invalid name'),
  shortName: yup
    .string()
    .required('Short name is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Invalid name'),
  description: yup.string(),
  storeFeeSecondary: yup
    .number()
    .typeError('Please enter a number')
    .required('Percentage is required')
    .test('min', 'Minimum should be greater than zero', (value) =>
      value ? value > 0 : false,
    )
    .test('max', 'Maximum should be 100', (value) =>
      value ? value <= 100 : false,
    ),
  storeFee: yup
    .number()
    .typeError('Please enter a number')
    .required('Percentage is required')
    .test('min', 'Minimum should be greater than zero', (value) =>
      value ? value > 0 : false,
    )
    .test('max', 'Maximum should be 100', (value) =>
      value ? value <= 100 : false,
    ),
  slug: yup
    .string()
    .matches(/^[a-zA-Z0-9-]+$/, 'Invalid slug')
    .required('Slug is required'),
  className: yup
    .string()
    .required('Classname is required')
    .matches(/^[A-Z]{1}/, 'First letter should be capital')
    .matches(/[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')
    .min(1, 'minimum 1 character')
    .max(20, 'max 20 characters'),
  payoutWalletAddress: yup.string().required('Address is required'),
  isPublished: yup.boolean(),
  autoMint: yup.boolean(),
  contractCollectionType: yup
  .object()
  .shape({
    label: yup.string(),
    value: yup.string(),
  })
  .nullable()
  .required('Collection type is required'),
  network: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required('Network is required'),
  categories: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required('Category is required'),
});

function CollectionForm() {
  const { control, handleSubmit, errors, register, watch, reset } = useForm<CollectionFormValues>({
    resolver: yupResolver(CollectionSchema),
    defaultValues: {
      
    },
  });
  const collectionApi = useCollectionApi();
  const toast = useToaster();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [collectionLogo, setSelectedLogo] = useState<any>(null);
  const [dataCollectionLogo, setDataLogo] = useState<any>(null);
  const [bannerImage, setBannerImage] = useState<any>(null);
  const [dataBanner, setDataBanner] = useState<any>(null);
  const [data, setUserData] = useState<any>(null);
  const [collectionListValue, setColloectionListValue]= useState<any>(null);
  const [categoriesListValue, setCategoriesListValue]= useState<any>([]);
  const [networkListValue, setNetworkListValue]= useState<any>([]);
  const [logoId, setLogoId] = useState<any>(null);
  const [bannerId, setBannerId] = useState<any>(null);
  const [collectionSlug, setCollectionSlug] = useState<any>(null);
  const [collectionValues, setCollectionEvent]= useState<any>(null);

  const [collectionDataSave, setCollectionDataSave] = useState<any>([]);


  const useCollectionListsSelector: any = useCollectionListSelector();
  const useGenesisDetailssSelector: any = useGenesisDetailsSelector();


  const onSelectFile = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let chooseImage = URL.createObjectURL(e.target.files[0]);
      setSelectedLogo(chooseImage);
      setDataLogo({ file, fileName: file.name, mimeType: file.type });
      let formData: any = new FormData();
      formData.append(
        'file',
        file
      );
      collectionApi.callUpdateFile(
        formData,
        (message: string, resp: any) => {
          if (resp) {
            console.log('select logo id', resp)
            setLogoId(resp._id) 
          } else {
            toast.error(message);
          }
        },
        (message: string) => {
          toast.error(message);
        }
      );
    }
  };

  // <--------------this one for fav icon upload--------------------->


  const onSelectFavIcon = (e: any) => {
    let file = e.target.files[0];
    if (file) {
      let chooseImage = URL.createObjectURL(file);
      setBannerImage(chooseImage);
      setDataBanner({ file, fileName: file.name, mimeType: file.type });
      let formData: any = new FormData();
      formData.append(
        'file',
        file
      );
      collectionApi.callUpdateFile(
        formData,
        (message: string, resp: any) => {
          if (resp) {
            setBannerId(resp._id)
            
          } else {
            toast.error(message);
          }
        },
        (message: string) => {
          toast.error(message);
        }
      );
    }
  };

  useEffect(() => {
    console.log('useGenesisDetailssSelector', useGenesisDetailssSelector)
    // <--------------get details value from reducers selector after that set value--------------------->

    if(useCollectionListsSelector && useCollectionListsSelector.collection){
      useCollectionListsSelector.collection.contractCollectionType = {
        label: useCollectionListsSelector.collection?.contractCollectionType,
        value: useCollectionListsSelector.collection?.contractCollectionType,
      };
      useCollectionListsSelector.collection.categories = {
        label: useCollectionListsSelector?.collection?.categories[0]?.name,
        value: useCollectionListsSelector?.collection?.categories[0]?._id,
      };

      if(useCollectionListsSelector.collection && useCollectionListsSelector.collection.networkChainId){
      networkListValue && networkListValue?.length && networkListValue?.filter((x:any) => {
        console.log(x.chainId)
        console.log(useCollectionListsSelector?.collection?.networkChainId)
        if(x.chainId == useCollectionListsSelector?.collection?.networkChainId && x.chainLabel==useCollectionListsSelector?.collection?.networkChainLabel){
          useCollectionListsSelector.collection.networks = {
            label: useCollectionListsSelector.collection.networkChainLabel,
            value: x?._id,
          };
        }
      })
    }
  
    setColloectionListValue(useCollectionListsSelector.collection)
    localStorage.setItem('slug',useCollectionListsSelector?.collection?.slug)
    localStorage.setItem('collection_id',useCollectionListsSelector?.collection?._id)
    localStorage.setItem('collection_name',useCollectionListsSelector?.collection?.name)
    }
  }, [useCollectionListsSelector, useGenesisDetailssSelector]);

  useEffect(() => {

    // <-------------- after add if slug available then details call--------------------->
    // if(!localStorage.getItem('slug')){
    //   localStorage.setItem('slug','demo')
    //   setTimeout(() => {
    //     getCollectionList()
    //   }, 500);
    // }
    getCategoriesList()
    getNetworkList()

    if(localStorage.getItem('slug')){
    getCollectionList()
    }
    else{
      getCollectionListwithoutSlug()
    }
  }, [])



  // <--------------this one for add or edit collection submit by condition--------------------->


  const onSubmit = (values: CollectionFormValues) => {
    let params: any = { ...values };
      params['network'] = values.network.value;
      params['categories'] = [values.categories.value] 
      params['contractCollectionType'] = values.contractCollectionType.value;
      params['releaseDate'] = values.releaseDate
        ? moment.utc(values.releaseDate).format()
        : null;
      params['revealDate'] = values.revealDate
        ? moment.utc(values.revealDate).format()
        : null;
        params['logoImage']=logoId? logoId : useCollectionListsSelector?.collection?.logoImage?._id
        params['bannerImage']=bannerId ? bannerId : useCollectionListsSelector?.collection?.bannerImage?._id
        params['featuredImage']=logoId? logoId : useCollectionListsSelector?.collection?.featuredImage?._id
      if (useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.slug) {
        collectionUpdate(params);
      } else {
        collectionAdd(params)
      }
    // }
  };

  // <--------------this one for collection update--------------------->


  const collectionUpdate = (parms:any) => {
    collectionApi.callUpdateCollection(
    parms,
    (message: string, resp: any) => {
      if (resp) {
        toast.success(message);
        localStorage.setItem('slug',resp.slug)
        localStorage.setItem('slug',resp.slug)
        localStorage.setItem('collection_id',resp?._id)
        localStorage.setItem('collection_name',resp?.name)
        getCollectionList()
        
      } else {
        // toast.error(message);
      }
    },
    (message: string) => {
      // toast.error(message);
    }
  );
  }

  // <--------------this one for collection add--------------------->


  const collectionAdd = (parms:any) => {
    collectionApi.callAddCollection(
    parms,
    (message: string, resp: any) => {
      if (resp) {
        toast.success(message);
        if(resp.slug){
        setCollectionSlug(resp.slug)
        localStorage.setItem('slug',resp.slug)
        localStorage.setItem('collection_id',resp._id)
        localStorage.setItem('collection_name',resp.name)
        getCollectionList()
        }
        
      } else {
        toast.error(message);
      }
    },
    (message: string) => {
      toast.error(message);
    }
  );
  }

  // <--------------this one for collection details--------------------->


  const getCollectionList = () => {
    const params:any = { 
    };

    collectionApi.callGetCollection(
      params,
      (message: string, resp: any) => {
        console.log(resp)
        setCollectionEvent(resp)
        // console.log('resp', resp.data[0])
        // setColloectionListValue(resp.data[0])
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };


  const getCollectionListwithoutSlug = () => {
    const params:any = { 
    };

    collectionApi.callWithoutSlugCollection(
      params,
      (message: string, resp: any) => {
        console.log(resp)
        if(resp && resp?.data && resp?.data?.length!=0){
        console.log('resp', resp?.data[0]?.slug)
        localStorage.setItem('slug',resp?.data[0]?.slug)
        localStorage.setItem('collection_id',resp?.data[0]?._id)
        localStorage.setItem('collection_name',resp?.data[0]?.name)

        let networkss:any={
          label: resp?.data[0]?.networkChainLabel,
          value: resp?.data[0]?.network
        }

        let collectsType: any={
          label: resp?.data[0]?.contractCollectionType,
          value: resp?.data[0]?.contractCollectionType
        }

        let categoriesType: any={
          label: resp?.data[0]?.categories[0]?.name,
          value: resp?.data[0]?.categories[0]?._id
        }
        reset({
          name: resp?.data[0]?.name,
          slug: resp?.data[0]?.slug,
          description: resp?.data[0]?.description,
          shortName : resp?.data[0]?.shortName,
          className: resp?.data[0]?.className,
          contractCollectionType : collectsType,
          categories: categoriesType,
          network: networkss,
          releaseDate:  new Date(resp?.data[0]?.createdAt),
          revealDate:  new Date(resp?.data[0]?.createdAt),
          about: resp?.data[0]?.about,
          storeFee: resp?.data[0]?.storeFee,
          storeFeeSecondary: resp?.data[0]?.storeFeeSecondary,
          payoutWalletAddress: resp?.data[0]?.payoutWalletAddress
        });
      }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  // <--------------this one for categories list--------------------->

  const getCategoriesList = () => {
    const params:any = { 
    };

    collectionApi.callGetCategories(
      params,
      (message: string, resp: any) => {
        setCategoriesListValue(resp.data)

      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  // <--------------this one for network list--------------------->

  const getNetworkList = () => {
    const params:any = { 
    };

    collectionApi.callGetNetwork(
      params,
      (message: string, resp: any) => {
        setNetworkListValue(resp?.data)

      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="information get_start tab-sec-n settings-custom-wrapper">
                <div className="row">
                  <div className="col-12 px-3">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      {/* <h4 className="mb-0 font-size-18 mb-3">{data?.name}</h4> */}
                    </div>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={window.location.hash || "#general"}
                      onSelect={(tab) =>
                        history.push(`${window.location.pathname}${tab}`)
                      }
                      className="mb-3"
                    >
                      <Tab eventKey="#general" title="Details">
                      <div className="mt-4">
                        <div className="payment_card px-0">
                          <div className="login-text">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="row">
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={useCollectionListsSelector?.collection &&  useCollectionListsSelector?.collection?.name ? useCollectionListsSelector.collection?.name :''}
                                        control={control}
                                        name="name"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.name}
                                            placeholder="Enter Name"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Short Name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.shortName ? useCollectionListsSelector?.collection?.shortName :''}
                                        control={control}
                                        name="shortName"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            // readOnly={data?.slug}
                                            error={errors.shortName}
                                            placeholder="Enter Short Name"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Slug{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.slug ? useCollectionListsSelector?.collection?.slug :''}
                                        control={control}
                                        name="slug"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            // readOnly={data?.slug}
                                            error={errors.slug}
                                            placeholder="Enter Slug"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Classname{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.className ? useCollectionListsSelector?.collection?.className :''}
                                        control={control}
                                        name="className"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            // readOnly={data?.slug}
                                            error={errors.className}
                                            placeholder="Classname"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                         Token Standard{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={useCollectionListsSelector?.collection?.contractCollectionType ? useCollectionListsSelector?.collection?.contractCollectionType :''}
                                        control={control}
                                        name="contractCollectionType"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <SelectInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            options={CONTRACT_TYPE.map(
                                              (opt: any) => ({
                                                label: opt.label,
                                                value: opt.value,
                                              }),
                                            )}
                                            isDisabled={data?.slug}
                                            error={
                                              errors.contractCollectionType
                                            }
                                            placeholder={`Collection Type`}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Blockchain{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.networks ? useCollectionListsSelector?.collection?.networks :''}
                                        control={control}
                                        name="network"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <SelectInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            // options={
                                            //   Object.values(networks).length
                                            //     ? Object.values(networks).map(
                                            //         (opt: any) => ({
                                            //           label: opt.chainLabel,
                                            //           value: opt.id,
                                            //         }),
                                            //       )
                                            //     : []
                                            // }
                                            options={
                                              networkListValue.length
                                                ? networkListValue.map(
                                                    (opt: any) => ({
                                                      label: opt.chainLabel,
                                                      value: opt._id,
                                                    }),
                                                  )
                                                : []
                                            }
                                            isDisabled={useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.collection?.slug}
                                            error={errors.network}
                                            placeholder={`Network`}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Category{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.categories ? useCollectionListsSelector?.collection?.categories :''}
                                        control={control}
                                        name="categories"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <SelectInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            options={
                                              categoriesListValue.length
                                                ? categoriesListValue.map(
                                                    (opt: any) => ({
                                                      label: opt.name,
                                                      value: opt._id,
                                                    }),
                                                  )
                                                : []
                                            }
                                            error={errors.categories}
                                            placeholder={`Category`}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Release Date{' '}
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.createdAt ? new Date(useCollectionListsSelector?.collection?.createdAt) :''}
                                        control={control}
                                        name="releaseDate"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <DateInput
                                            inputRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            // isClearable={true}
                                            onBlur={onBlur}
                                            placeholder="Release date"
                                            // showTimeSelect={true}
                                            dateFormat="MM-dd-yyyy hh:mm a"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Reveal Date{' '}
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.createdAt ? new Date(useCollectionListsSelector?.collection?.createdAt) :''}

                                        control={control}
                                        name="revealDate"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <DateInput
                                            inputRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            // isClearable={true}
                                            onBlur={onBlur}
                                            placeholder="Reveal date"
                                            // showTimeSelect={true}
                                            dateFormat="MM-dd-yyyy hh:mm a"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12" />
                                <div className="col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        About{' '}
                                      </label>
                                      <Controller
                                        defaultValue={ useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.about ? useCollectionListsSelector?.collection?.about :''}

                                        control={control}
                                        name='about'
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTexAreatInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            placeholder="Enter About"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 mt-3">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Description{' '}
                                      </label>
                                      <Controller
                                        defaultValue={useCollectionListsSelector?.collection &&  useCollectionListsSelector?.collection?.description ? useCollectionListsSelector?.collection?.description :''}
                                        control={control}
                                        name={'description'}
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <TinyMceEditor
                                            name={name}
                                            onChange={(e) => {
                                              onChange(e);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            inline={false}
                                            minHeight={240}
                                            showSupportText={false}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <h3 className="mt-3">Royalties</h3> */}
                                <p className="text-small mt-4">
                                  Collect a fee when a user re-sells an item you
                                  originally created. This is deducted from the
                                  final sale price and paid monthly to a payout
                                  address of your choosing.
                                </p>

                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Store Fees in Primary Sales{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={useCollectionListsSelector?.collection &&  useCollectionListsSelector?.collection?.storeFee ? useCollectionListsSelector?.collection?.storeFee :''}
                                        control={control}
                                        name="storeFee"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="number"
                                            error={errors.storeFee}
                                            placeholder="0.0"
                                          />
                                        )}
                                      />
                                      <p className="text-small mt-3">
                                        %Percentage of the each transaction
                                        amount in primary sales to be shared
                                        with your store
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Store Fees in Secondary Sales{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.storeFeeSecondary ? useCollectionListsSelector?.collection?.storeFeeSecondary :''}
                                        control={control}
                                        name="storeFeeSecondary"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="number"
                                            error={errors.storeFeeSecondary}
                                            placeholder="0.0"
                                          />
                                        )}
                                      />
                                      <p className="text-small mt-3">
                                        %Percentage of the each transaction
                                        amount in primary sales to be shared
                                        with your store
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Your Payout Wallet Address{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Controller
                                        defaultValue={useCollectionListsSelector?.collection && useCollectionListsSelector?.collection?.payoutWalletAddress ? useCollectionListsSelector?.collection?.payoutWalletAddress :''}
                                        control={control}
                                        name="payoutWalletAddress"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.payoutWalletAddress}
                                            placeholder="Enter wallet address"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12" />
                                <div className="col-sm-6 col-12 mt-3">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Logo{' '}
                                        <span className="text-danger">*</span>{' '}
                                        (276px by 276px)
                                      </label>
                                      <div>
                                        <input
                                          type="file"
                                          className="form-control"
                                          accept=".jpg, .jpeg, .png"
                                          onChange={(e) => onSelectFile(e)}
                                        />

                                        <div>
                                          {useCollectionListsSelector?.collection && !collectionLogo ? (
                                            <img
                                              src={
                                                useCollectionListsSelector?.collection?.logoImage?.fileUrl ||
                                                `../images/no_image.png`
                                              }
                                              className="imageHeightShowing leftPositionMove mt-2"
                                              alt="Logo"
                                            />
                                          ) : collectionLogo ? (
                                            <img
                                              src={collectionLogo}
                                              className="imageHeightShowing leftPositionMove mt-2"
                                              alt="Logo"
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6 col-12 mt-3">
                                  <div className="login-text ">
                                    <div className="form-group">
                                      <label>
                                        Banner Image{' '}
                                        <span className="text-danger">*</span>{' '}
                                        (1410px by 280px)
                                      </label>
                                      <div>
                                        <input
                                          type="file"
                                          className="form-control"
                                          accept=".jpg, .jpeg, .png"
                                          id="favicon"
                                          onChange={(e) => onSelectFavIcon(e)}
                                        />

                                        <div>
                                          {useCollectionListsSelector?.collection && !bannerImage ? (
                                            <img
                                              src={
                                                useCollectionListsSelector?.collection?.bannerImage?.fileUrl ||
                                                `../images/no_image.png`
                                              }
                                              className="imageHeightShowing leftPositionMove mt-2"
                                              alt="Banner Image"
                                            />
                                          ) : bannerImage ? (
                                            <img
                                              src={bannerImage}
                                              className="imageHeightShowing leftPositionMove mt-2"
                                              alt="Banner Image"
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                {/* <div className="col-sm-6 col-12">
                                  <div className="form-group">
                                    <div className="form-check form-switch form-check-new">
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked1"
                                      >
                                        Published (Public)?{' '}
                                      </label>
                                      <input
                                        disabled={
                                          data?.status !== DeployStatus.DEPLOYED
                                        }
                                        {...register('isPublished')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked1"
                                      />
                                    </div>
                                  </div>
                                </div> */}
                                {/*<div className="col-sm-6 col-12">
                        <div className="form-group">
                          <div className="form-check form-switch form-check-new">
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckChecked"
                            >
                              Transfer after CC payment?{' '}
                            </label>
                            <input
                              {...register('autoMint')}
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                            />
                          </div>
                        </div>
                      </div>*/}

                                {/* <div className="col-sm-6 col-12">
                                  <div className="form-group">
                                    <Controller
                                      defaultValue=""
                                      control={control}
                                      name="isFeatured"
                                      render={({ onChange, onBlur, value, name, ref }) => (
                                        <SwitchInput
                                          onChange={onChange}
                                          name={name}
                                          value={value}
                                          inputRef={ref}
                                          id={name}
                                          label={'Featured?'}
                                        />
                                      )}
                                    />
                                  </div>
                                </div> */}

                                {/* {isAutoMint && ( */}
                                  {/* <div className="col-sm-12 col-12">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Private Key
                                      </label>
                                      <Controller
                                        defaultValue=""
                                        control={control}
                                        name="privateWalletAddress"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                          <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="password"
                                            // error={errors.privateWalletAddress}
                                            placeholder="Enter private key"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div> */}
                                {/* )} */}

                                <div className="row">
                                  <div className="col-lg-6">
                                    <button
                                      type="submit"
                                      className="btn btn-primary ml-0"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-lg-8">
                                    {data?.status === 'pending' && (
                                      <div
                                        className="alert alert-info"
                                        role="alert"
                                      >
                                        Your contract is being deployed.{' '}
                                        <a
                                          href={data?.transactionLink}
                                          target="_blank"
                                        >
                                          Click here
                                        </a>{' '}
                                        to see details
                                      </div>
                                    )}
                                    {data?.status === 'deployed' && (
                                      <div
                                        className="alert alert-success"
                                        role="alert"
                                      >
                                        Your contract on the{' '}
                                        <strong>{data?.network?.label}</strong>,
                                        is successfully deployed.{' '}
                                        <a
                                          href={data?.transactionLink}
                                          target="_blank"
                                        >
                                          Click here
                                        </a>{' '}
                                        to see details. Contract Address:{' '}
                                        <a
                                          href={data?.contractLink}
                                          target="_blank"
                                          style={{ wordBreak: 'break-word' }}
                                        >
                                          {data?.address}
                                        </a>
                                      </div>
                                    )}
                                    {data?.status === 'failed' && (
                                      <div
                                        className="alert alert-danger"
                                        role="alert"
                                      >
                                        Your contract could not be deployed.
                                        Please{' '}
                                        <a
                                          href={data?.transactionLink}
                                          target="_blank"
                                        >
                                          Click here
                                        </a>{' '}
                                        to check details
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      </Tab>
                      {/* <Tab
                      eventKey="#socials"
                      title="Socials"
                      disabled={!data?.slug}
                    >
                      <SocialCollection
                        isAdd={true}
                        slug={data.slug}
                        socials={socials}
                      />
                    </Tab> */}
                      {/* <Tab
                      eventKey="#pre-sale"
                      title="Presale"
                      disabled={!data?.slug}
                    >
                      <PresaleTab
                        slug={data.slug}
                        preSaleRegistrationSettings={
                          preSaleRegistrationSettings
                        }
                        collection={data}
                      />
                    </Tab> */}
                      <Tab
                        eventKey="#genesis-drop"
                        title="Genesis Drop"
                        // disabled={!data?.slug}
                      >
                        <GenesisUser slug={useCollectionListsSelector?.collection?.slug} collection={useCollectionListsSelector?.collectionEvents}/>
                      </Tab>
                      <Tab
                        eventKey="#payout-group"
                        title="Payout Group"
                        // disabled={!data?.slug}
                      >
                        {/* <PayoutGroupTab slug={data.slug} collection={data} /> */}
                        <PayoutTab slug={useCollectionListsSelector?.collection?.slug} collection={useCollectionListsSelector?.collectionEvents}/>
                      </Tab>
                      {/* <Tab
                        eventKey="#settings"
                        title="Settings"
                        // disabled={!data?.slug}
                      >
                        <SettingsUser slug={localStorage.getItem('slug')? localStorage.getItem('slug') : ''} collection={useCollectionListsSelector}/>
                      </Tab> */}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* </form> */}
    </>
  );
}

export default CollectionForm;
