import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { PER_PAGE, URLS } from "src/_config";
import Pagination from "react-js-pagination";

import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import {
  AddGroupReq,
  collectionType,
  filterUrlValues,
  OptionValue,
} from "src/_common/interfaces/common";
// import { usePayoutApi } from "src/_common/hooks/actions/payout/payoutApiHook";
import { useProductApi } from "src/_common/hooks/actions/product/productApiHook";
import { useOrderApi } from "src/_common/hooks/actions/order/orderApiHook";
import queryString from "query-string";
import * as qs from "query-string";
import { useLocation } from "react-router-dom";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import {
  AddProductReq,
  BLOCKCHAIN_TYPE,
  CONTRACT_TYPE,
  STATUS_TYPE,
} from "src/_common/interfaces/common";
import DateInput from "src/_common/components/form-elements/dateInput";
interface Props {
  slug: string;
  collection: any;
}
interface FormValues {
  orderNo: string;
  transactionHash: string;
  status: string;
  orderDateMin: any;
  orderDateMax: any;
  amountMin: number;
  amountMax: number;
}

const resetValue = {
  orderNo: "",
  transactionHash: "",
  status: "",
  orderDateMin: "",
  orderDateMax: "",
  amountMin: "",
  amountMax: "",
};

// PayoutListForm

const OrderListForm = ({ slug, collection }: Props) => {
  const { control, handleSubmit, errors, reset } = useForm<any>({
    // resolver: yupResolver(CollectionSchema),
    defaultValues: resetValue,
  });

  // const payoutApi = usePayoutApi();
  const orderApi = useOrderApi();

  const toast = useToaster();
  const history = useHistory();
  const location = useLocation();

  const [data, setData] = useState<any>([]);
  const [filtersFieldShowHide, setfiltersFieldShowHide] =
    useState<boolean>(false);

  const [activePage, setactivePage] = useState<number>(1);

  const [category, setCategory] = useState<any>([]);

  // const collections_id:any=localStorage.getItem('collection_id')
  // console.log("collections_id36",collections_id)
  const collections_name: any = localStorage.getItem("collection_name");

  useEffect(() => {
    // getPayoutList()
    // getOrderList();
  }, []);

  // this one for filter data submit and set page 1 for pagination

  const onSubmit = (data: any) => {
    let params = { ...data };
    console.log("paramsdata", params);
    params = filterUrlValues(params);
    if (params.status) {
      params["status"] = params.status.value;
    }

    if (params.orderDateMin) {
      params["orderDateMin"] = moment(params.orderDateMin)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    if (params.orderDateMax) {
      params["orderDateMax"] = moment(params.orderDateMax)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    params["page"] = 1;
    params["perPage"] = PER_PAGE;

    setactivePage(1);
    history.push(URLS.ORDER_LIST + `?${qs.stringify(params)}`);
    orderApi.callListOrder(
      params,
      (message: string, resp: any) => {
        if (resp) {
          console.log("res", resp);
          setData(resp);
          // setCategory(resp.data[0].categories)
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };
  // this one for reset  filter data
  const resetData = () => {
    reset(resetValue);
    history.push(URLS.ORDER_LIST);
    let pageNumberFirstTime = 1;
    setactivePage(1);
    setTimeout(() => {
      getOrderList(pageNumberFirstTime, "reset");
    }, 500);
  };

  // if there have any params in url then it passes params through url

  useEffect(() => {
    const queryVal: any = queryString.parse(location.search);
    if (queryVal && queryVal?.page) {
      setactivePage(Number(queryVal?.page));
      reset(queryString.parse(location.search));
      console.log(queryString.parse(location.search));
      getOrderList(queryVal?.page, "first time");
    } else {
      getOrderList(1, "first time");
    }
  }, []);

  // pagination page change and set

  const handlePageChange = (pageNumber: any) => {
    let temp = { ...queryString.parse(location.search) };
    console.log("temp1", temp);
    temp["page"] = pageNumber;
    history.push(URLS.ORDER_LIST + `?${qs.stringify(temp)}`);
    console.log("temp", temp);
    console.log("pageNumber", pageNumber);
    setactivePage(pageNumber);
    getOrderList(pageNumber, "page change");
  };

  // if filter button click then show filter fields

  const filterShowHide = (flts: any) => {
    setfiltersFieldShowHide(flts);
  };

  const getOrderList = (pageNumber: any, type: any) => {
    const params: any = {
      perPage: PER_PAGE,
    };

    if (type != "reset") {
      for (let [key, value] of Object.entries(
        queryString.parse(location.search)
      )) {
        params[key] = value;
      }
    }
    params["page"] = pageNumber;

    orderApi.callListOrder(
      params,
      (message: string, resp: any) => {
        if (resp) {
          console.log("res", resp);
          setData(resp);
          // setCategory(resp.data[0].categories)
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const goToViewOrder = (orderId: any) => {
    history.push({
      pathname: `${URLS.ORDER_DETAILS}`,
      state: { orderId: orderId },
    });
  };

  const goToBulkUploadPage = () => {
    history.push({
      pathname: URLS.UPLOAD_PRODUCT,
    });
  };

  console.log("data", data, "cate", category, "status");

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="information single_pro_btn common-white-box">
                <div className="c_detail ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>Orders</h2>

                    <div className="d-flex align-items-center">
                      <div
                        className="cursor-pointer d-flex justify-content-between align-items-baseline ms-3 flter-blue-btn"
                        onClick={() => filterShowHide(!filtersFieldShowHide)}
                      >
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
                {filtersFieldShowHide && (
                  <div className="row mb-3">
                    <div className="d-flex flex-row-reverse">
                      <div
                        className="cursor-pointer d-flex justify-content-between align-items-baseline ms-3 flter-blue-btn mb-3"
                        onClick={() => filterShowHide(!filtersFieldShowHide)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="">
                          <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="row mb-3">
                                <div className="col-lg-4 col-md-3 col-12 mt-mobile-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Order Number{" "}
                                    </label>
                                    <Controller
                                      defaultValue=""
                                      control={control}
                                      name="orderNo"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          error={errors.orderNo}
                                          placeholder="Enter Order No"
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-3 col-12 mt-mobile-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Transaction Hash{" "}
                                    </label>
                                    <Controller
                                      defaultValue=""
                                      control={control}
                                      name="transactionHash"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          error={errors.transactionHash}
                                          placeholder="Enter Txn Hash"
                                        />
                                      )}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-3 col-12 mt-mobile-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Status{" "}
                                    </label>
                                    <Controller
                                      defaultValue={""}
                                      control={control}
                                      name="status"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <SelectInput
                                          className="box"
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          options={STATUS_TYPE.map(
                                            (opt: any) => ({
                                              label: opt.label,
                                              value: opt.value,
                                            })
                                          )}
                                          error={errors.status}
                                          placeholder={`Select Status`}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-3 col-12 mt-mobile-2 mt-4">
                                  <div className="row">
                                    <div className="col-6 form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Minimum Order Date{" "}
                                      </label>
                                      <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="orderDateMin"
                                        render={({
                                          onChange,
                                          onBlur,
                                          value,
                                          name,
                                          ref,
                                        }) => (
                                          <DateInput
                                            inputRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            // isClearable={true}
                                            onBlur={onBlur}
                                            placeholder="Minimum Date"
                                            // showTimeSelect={true}
                                            dateFormat="MM-dd-yyyy"
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="col-6 form-group">
                                      <label htmlFor="exampleInputEmail1">
                                        Maximum Order Date{" "}
                                      </label>
                                      <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="orderDateMax"
                                        render={({
                                          onChange,
                                          onBlur,
                                          value,
                                          name,
                                          ref,
                                        }) => (
                                          <DateInput
                                            inputRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            // isClearable={true}
                                            onBlur={onBlur}
                                            placeholder="Maximum Date"
                                            // showTimeSelect={true}
                                            dateFormat="MM-dd-yyyy"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-3 col-12 mt-mobile-2 mt-4">
                                  <div className="row">
                                  <div className="col-6 form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Minimum Price Range{" "}
                                    </label>
                                    <Controller
                                      defaultValue=""
                                      control={control}
                                      name="amountMin"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="number"
                                          error={errors.amountMin}
                                          placeholder="Enter Minimum Amount"
                                        />
                                      )}
                                    />
                                  </div>

                                  <div className="col-6 form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Maximum Price Range{" "}
                                    </label>
                                    <Controller
                                      defaultValue=""
                                      control={control}
                                      name="amountMax"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="number"
                                          error={errors.amountMax}
                                          placeholder="Enter Maximum Amount"
                                        />
                                      )}
                                    />
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                              <div className="d-flex justify-content-end mb-8 mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary btnclours mx-2"
                                >
                                  Filter
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger btnclours"
                                  onClick={resetData}
                                >
                                  Clear
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="table-responsive pro_table tableFixHead">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>
                          {/* <i
                          className="fa fa-refresh cursor-pointer"
                        /> */}
                        </th>
                        <th>Order #</th>
                        <th>Status</th>
                        <th>Transaction #</th>
                        <th>Price</th>
                        <th>Txn Fees</th>
                        <th>Total</th>
                        <th>Date</th>

                        <th>Action</th>
                      </tr>
                    </thead>

                    {data && data?.total > 0 ? (
                      data?.data?.map((item: any, index: number) => {
                        return (
                          <tbody key={item._id}>
                            <tr>
                              <td></td>
                              <td>{item?.orderNo}</td>
                              <td>{item?.status}</td>
                              <td>{"N.A"}</td>
                              <td>{item?.amount}</td>
                              <td>{item?.tax}</td>

                              <td>{item?.tax + item?.amount}</td>
                              <td>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn-blue "
                                  title="View"
                                  onClick={() => {
                                    goToViewOrder(item?.orderNo);
                                  }}
                                >
                                  <i className="fa fa-eye" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={9} align="center">
                            No results found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {data && data?.total > 0 ? (
                <div className="d-flex justify-content-end mt-3">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={PER_PAGE}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    innerClass="pagination mt-3 font-link"
                    itemClass="paginate_button page-item"
                    linkClass="page-link"
                    activeClass="active"
                    hideFirstLastPages={true}
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* {showModal && (
        <PayoutGroupModal show={true} close={closeModal} id={rowId} />
      )} */}
      </section>

      {/* </form> */}
    </>
  );
};

export default OrderListForm;
