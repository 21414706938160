import moment from 'moment';


export interface OptionValue {
  value: string;
  label: string;
}

export enum ContractType {
  // ERC721 = "ERC721 (Non-Fungible Token)",
  ERC1155 = "ERC1155 (Semi-Fungible Token)"
}
export enum BlockChainType {
  // ERC721 = "ERC721 (Non-Fungible Token)",
  POLYGON = "POLYGON"
}

export enum StatusType{
  COMPLETED="completed",
  INACTIVE="inactive",
  PENDING="pending",
  ACTIVE="active",
  MINTED="minted"

}

export const CONTRACT_TYPE = [
  // { label: 'ERC721 (Non-Fungible Token)', value: ContractType.ERC721 },
  { label: 'ERC1155 (Semi-Fungible Token)', value: ContractType.ERC1155 },
];

export const BLOCKCHAIN_TYPE = [
  // { label: 'ERC721 (Non-Fungible Token)', value: ContractType.ERC721 },
  { label: 'POLYGON', value: BlockChainType.POLYGON },
];



export const NETWORK_TYPE = [
  { label: 'ethereum', value: 'ethereum' },
  // { label: 'ERC1155 (Semi-Fungible Token)', value: ContractType.ERC1155 },
];


export const STATUS_TYPE= [
  // { label: 'ERC721 (Non-Fungible Token)', value: ContractType.ERC721 },
  { label: 'ACTIVE', value: StatusType.ACTIVE },
  { label: 'INACTIVE', value: StatusType.INACTIVE },

  { label: 'MINTED', value: StatusType.MINTED },
  { label: 'COMPLETED', value: StatusType.COMPLETED },
  { label: 'PENDING', value: StatusType.PENDING },




];

export interface CollectionProps {
  editCollection: (data: FormData) => void;
  addCollection: (data: FormData) => void;
  data: any;
  getDetails: () => void;
  networks: any;
  socials: any;
  preSaleRegistrationSettings: any;
  categories: any;
}


export interface AddGroupReq {
  groupName: string;
  payees: PayeeGroup[];
  collectionEntity: string;
  isDefault: boolean;
  ethTransaction?: string;
}

export interface AddProductReq {
  itemName: string;
  logoImage:string;
  url: string;
  description: string;
  contractCollectionType: OptionValue;
  blockchain: OptionValue;
  network: OptionValue;
  categories: OptionValue;
  explicit:boolean;
  unlockable:boolean;
  unlockableText:string;
  supply:number;
  properties: PropertiesGroup[];

}

export interface PayOutTab {
  payout: OptionValue
}


interface PayeeGroup {
  name?: string;
  email?: string;
  cryptoAddress: string;
  percentageFees: string;
}
interface PropertiesGroup {
  type?: string;
  name?: string;

}

export const collectionType = (type:string) => {
  return type === ContractType.ERC1155 ? ContractType.ERC1155 : ContractType.ERC1155
  }


  export const convertStatus = (type: string) => {
    if (type === ProductType.NFT) {
      return 'NFT';
    } else {
      return 'Smart Contract';
    }
  };

  export enum ProductType {
    NFT = 'nft',
    SMART_CONTRACT = 'smartContract',
  }


  export const currencyUnit = (type: string = 'parent', unit: string = '') => {
    //let unit = localStorage.getItem('units');
    if (unit === 'polygon') {
      switch (type) {
        case 'child':
          return 'd-matic-price';
        case 'parent':
          return 'd-matic-sec-p';
  
        case 'product-input':
          return 'd-matic';
      }
    } else if (unit === 'ethereum') {
      switch (type) {
        case 'child':
          return 'd-ether-price';
        case 'parent':
          return 'd-ether-sec-p';
        case 'product-input':
          return 'd-ether';
      }
    } else if (unit === 'avalanche') {
      switch (type) {
        case 'child':
          return 'd-avalanche-price';
        case 'parent':
          return 'd-avalanche-sec-p';
        case 'product-input':
          return 'd-avalanche';
      }
    } else if (unit === 'binance') {
      switch (type) {
        case 'child':
          return 'd-binance-price';
        case 'parent':
          return 'd-binance-sec-p';
        case 'product-input':
          return 'd-binance';
      }
    }
  };

  export const formatDate = (date: string) => {
    return date ? moment(date).format('MM-DD-YYYY') : 'N.A';
  };

  export const formatTime = (time: string) => {
    return time ? moment(time, 'hh:mm:ss').format('hh:mm a') : 'N.A';
  };

  export const filterUrlValues = (params: any) => {
    //loop and remove blank or undefined values
    return Object.fromEntries(Object.entries(params).filter(([_, v]) => v));
  };