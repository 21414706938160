import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';

export function* getCollectionList(action: any): any {
   const getSlug= localStorage.getItem('slug')
    const slug='avijit'
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.COLLECTION_LIST}/${getSlug}?fields=logoImage,bannerImage,featuredImage,categories,defaultPayoutGroup`);
        if (resp && resp.status==200) {
            yield put({
                type: ACTIONS.COLLECTION.COLLECTION_LIST,
                payload: resp.data.data
            })

            // yield put({
            //     type: ACTIONS.GENESIS.GENESIS_DETAILS,
            //     payload: resp.data.data.collectionEvents
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCategoriesList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.CATEGORIES.CATEGORIES_LIST);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}

export function* getNetworkList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.NETWORK.NETWORK_LIST);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}



export function* updateCollection(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, `${API_URL.COLLECTION.UPDATE}/${data?.slug}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateFile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.FILE}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addCollection(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.ADD}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addGenesis(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.GENESIS}/${data?.collection_slug}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateGenesis(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.GENESIS}/${data.collection_slug}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* updateProductFile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.PRODUCT_FILE}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        console.log(e)
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getFileProductView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.PRODUCT_PREVIEW}/${data?.parms?.collection_id}/jobs/${data?.parms?.jobId}`);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}


export function* confirmBulkUpload(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.PRODUCT_PREVIEW}/${data?.parms?.collection_id}/jobs/${data?.parms?.jobId}`, data?.parms, true);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}


export function* getWithoutSlugCollectionList(action: any): any {
    const getSlug= localStorage.getItem('slug')
     const slug='avijit'
     try {
         const data = action.payload;
         const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.COLLECTION_LIST}?fields=logoImage,bannerImage,featuredImage,categories,defaultPayoutGroup`);
         if (resp && resp.status==200) {
            console.log('collection list data', resp.data.data.data[0])
            let collections:any={collection: resp.data.data.data[0]}
             yield put({
                 type: ACTIONS.COLLECTION.COLLECTION_LIST,
                 payload: collections
             })
 
             // yield put({
             //     type: ACTIONS.GENESIS.GENESIS_DETAILS,
             //     payload: resp.data.data.collectionEvents
             // })
             // yield put({
             //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
             //     payload: resp.data.data.status
             // })
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
     } catch (e:any) {
         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
     }
 }

 export function* getSettingsDetails(action: any): any {
     try {
         const data = action.payload;
         const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.SETTINGS_DETAILS}?group=${data?.tabName}`);
         if (resp && resp.status==200) {
            console.log('settings details data', resp.data)
            //  yield put({
            //      type: ACTIONS.COLLECTION.COLLECTION_LIST,
            //      payload: collections
            //  })
 
             // yield put({
             //     type: ACTIONS.GENESIS.GENESIS_DETAILS,
             //     payload: resp.data.data.collectionEvents
             // })
             // yield put({
             //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
             //     payload: resp.data.data.status
             // })
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
     } catch (e:any) {
         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
     }
 }


 export function* updateSettingss(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.SETTINGS_DETAILS}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addSettingss(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COLLECTION.SETTINGS_DETAILS}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* getCustomerList(action: any): any {
    const getSlug= localStorage.getItem('slug')
     try {
         const data = action.payload;
         const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.CUSTOMER_LIST}`, data, true);
         if (resp && resp.status==200) {
            //  yield put({
            //      type: ACTIONS.COLLECTION.CUSTOMER_LIST,
            //      payload: resp.data.data
            //  })
 
             // yield put({
             //     type: ACTIONS.GENESIS.GENESIS_DETAILS,
             //     payload: resp.data.data.collectionEvents
             // })
             // yield put({
             //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
             //     payload: resp.data.data.status
             // })
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
     } catch (e:any) {
         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
     }
 }


 export function* customerDetails(action: any): any {
    try {

        const data = action.payload;
        let customersId= data?.customerId
        delete data['customerId']
        // console.log("data", data)
        const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.CUSTOMER_LIST}/${customersId}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* customerDetailsOrders(action: any): any {
    try {

        const data = action.payload;
        let customersId= data?.customerId
        delete data['customerId']
        // console.log("data", data)
        const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.CUSTOMER_LIST}/${customersId}/orders`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* customerDetailsProducts(action: any): any {
    try {

        const data = action.payload;
        let customersId= data?.customerId
        delete data['customerId']
        // console.log("data", data)
        const resp = yield call(CallApi.GET, `${API_URL.COLLECTION.CUSTOMER_LIST}/${customersId}/nfts?fields=logoImage`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



