import { ActionExtended } from 'src/_common/interfaces/ActionExtended';
import { User } from 'src/_common/interfaces/models/user';
import { ACTIONS } from 'src/_config'

export interface UserReducer {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;

  socketContainer: null,
  loggedInFromOtherLocation: any,
}

const initialState: UserReducer = {
  user: null,
  token: null,
  isAuthenticated: false,
  socketContainer: null,
  loggedInFromOtherLocation: null,
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      };
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case ACTIONS.USER.ONLINE_STATUS_CHANGE:
      let tempStatus = {
        onlineStatus: action.payload
      }
      var newUser = { ...state.user, ...tempStatus }
      return {
        ...state,
        user: newUser,
      };
    case ACTIONS.USER.PROFILE:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      }
    case ACTIONS.USER.SOCKET_INSTANCE_CONTAINER:
      return {
        ...state,
        socketContainer: action.payload
      };

    default:
      return state;
  }
};

export default userReducer;
