import { } from 'src/_common/interfaces/ApiReqRes';
import { SAGA_ACTIONS } from 'src/_config'
import { useApiCall } from '../common/appApiCallHook'

export function useCommonApi() {

  const callApi = useApiCall()

  const getGenderList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GENDER_LIST, null, onSuccess, onError);
  }
  const getCountryList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.COUNTRY_LIST, null, onSuccess, onError);
  }
  const getSecretQuestion = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.SECRET_QUESTION_LIST, null, onSuccess, onError);
  }

  const getToken = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.TOKEN, null, onSuccess, onError);
  }
  

  
  return {
    callGetGenderList: getGenderList,
    callGetCountryList: getCountryList,
    callGetSecretQuestion: getSecretQuestion,
    callGetToken: getToken
  }
}