// let apiBaseUrl = 'https://shopifydapp-api.dreamztesting.com/api/v1/';
//  let  apiBaseUrl = 'https://f13b-45-64-221-241.ngrok.io/api/v1/';
// let socketUrl = 'https://backendrodeo.glohtesting.com:6682';

let appStage = process.env.REACT_APP_STAGE
let apiBaseUrl=''
let socketUrl=''

if (appStage == 'dev') {
  // apiBaseUrl = 'https://551f-45-64-221-241.ngrok.io/api/v1/';
  apiBaseUrl = 'https://shopifydapp-api.dreamztesting.com/api/v1/';
  // socketUrl = 'https://backendrodeo.glohtesting.com:6682';
} else if (appStage == 'stage') {
  apiBaseUrl = 'https://shopifydapp-api.dreamztesting.com/api/v1/';
  // let  apiBaseUrl = 'https://f13b-45-64-221-241.ngrok.io/api/v1/';
  // socketUrl = 'https://backendrodeo.glohtesting.com:6682';
} else if (appStage == 'prod') {
  // apiBaseUrl = 'https://f13b-45-64-221-241.ngrok.io/api/v1/';
  apiBaseUrl = 'https://shopifydapp-api.dreamztesting.com/api/v1/';
  // socketUrl = 'https://backendrodeo.glohtesting.com:6682';
}

export const API_BASE_URL = apiBaseUrl
export const SOCKETURL = socketUrl
