import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import {
  forgotPassword,
  getUserProfile,
  loginUser,
  loginUserWithEmail,
  loginUserWithOtp,
  logoutUser,
  registration,
  resetPassword,
  updateUserProfile,
} from './userSaga';

import { getToken } from './commonSaga';

import {
  getEventList,
  getEventGameList,
  getEventGameDetails,
  getUpcomingEventList,
  pickRider,
  joinEventGame,
  viewLeaderBoard,
  tvImageDetails,
  getPlayersList,
  bettingPointsSubmit,
  getPlayersDetails,
  saveBet
} from './eventSaga';

import {
  getCmsContent
} from './cmsSaga';
import { addCollection, addGenesis, addSettingss, confirmBulkUpload, customerDetails, customerDetailsOrders, customerDetailsProducts, getCategoriesList, getCollectionList, getCustomerList, getFileProductView, getNetworkList, getSettingsDetails, getWithoutSlugCollectionList, updateCollection, updateFile, updateGenesis, updateProductFile, updateSettingss } from './collectionSaga';
import { addPayout, detailsPayout, editPayout, payoutStatusChangeForCollection } from './payoutSaga';
import { listPayout } from './payoutSaga';
import { addProduct, detailsProduct, editProduct, listProduct, updateFileProduct } from './productSaga';
import { listOrder, orderDetails } from './orderSaga';


export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.DETAILS, getUserProfile),
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, loginUserWithOtp),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_EMAIL, loginUserWithEmail),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateUserProfile),
    takeLatest(SAGA_ACTIONS.USER.REGISTRATION, registration),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),

    takeLatest(SAGA_ACTIONS.USER.LOGOUT, logoutUser),


    //Event Saga
    takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, getEventList),
    takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME, getEventGameList),
    takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME_DETAILS, getEventGameDetails),
    takeLatest(SAGA_ACTIONS.EVENT.PICK_RIDER, pickRider),
    takeLatest(SAGA_ACTIONS.EVENT.LEADERBOARD, viewLeaderBoard),
    takeLatest(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, getUpcomingEventList),
    takeLatest(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, joinEventGame),
    takeLatest(SAGA_ACTIONS.TV_IMAGE.DETAILS, tvImageDetails),
    takeLatest(SAGA_ACTIONS.EVENT.PLAYERS_LIST, getPlayersList),
    takeLatest(SAGA_ACTIONS.EVENT.BETTING_POINT_SUBMIT, bettingPointsSubmit),
    takeLatest(SAGA_ACTIONS.EVENT.PLAYERS_DETAILS, getPlayersDetails),
    takeLatest(SAGA_ACTIONS.EVENT.SAVE_BET, saveBet),

    //CMS Saga
    takeLatest(SAGA_ACTIONS.CMS.GET_CMS, getCmsContent),
    takeLatest(SAGA_ACTIONS.COLLECTION.COLLECTION_LIST, getCollectionList),
    takeLatest(SAGA_ACTIONS.CATEGORIES.CATEGORIES_LIST, getCategoriesList),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE, updateCollection),
    takeLatest(SAGA_ACTIONS.COLLECTION.FILE, updateFile),
    takeLatest(SAGA_ACTIONS.COLLECTION.ADD, addCollection),
    takeLatest(SAGA_ACTIONS.NETWORK.NETWORK_LIST, getNetworkList),
    takeLatest(SAGA_ACTIONS.PAYOUT.ADD, addPayout),
    takeLatest(SAGA_ACTIONS.PRODUCT.ADD, addProduct),

    takeLatest(SAGA_ACTIONS.PAYOUT.PAYOUT_LIST, listPayout),
    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_LIST, listProduct),

    takeLatest(SAGA_ACTIONS.PAYOUT.PAYOUT_DETAILS, detailsPayout),
    takeLatest(SAGA_ACTIONS.PAYOUT.UPDATE, editPayout),
    takeLatest(SAGA_ACTIONS.COLLECTION.ADD_GENESIS, addGenesis),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_GENESIS, updateGenesis),
    takeLatest(SAGA_ACTIONS.PAYOUT.PAYOUT_STATUS, payoutStatusChangeForCollection),
    takeLatest(SAGA_ACTIONS.COLLECTION.PRODUCT_FILE, updateProductFile),
    takeLatest(SAGA_ACTIONS.COLLECTION.PREVIEW_FILE, getFileProductView),
    takeLatest(SAGA_ACTIONS.COLLECTION.CONFIRM_BULK, confirmBulkUpload),
    takeLatest(SAGA_ACTIONS.COMMON.TOKEN, getToken),
    takeLatest(SAGA_ACTIONS.COLLECTION.COLLECTION_LIST_WITHOUT_SLUG, getWithoutSlugCollectionList),
    takeLatest(SAGA_ACTIONS.COLLECTION.STORE_DETAILS, getSettingsDetails),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_STORE, updateSettingss),
    takeLatest(SAGA_ACTIONS.COLLECTION.ADD_STORE, addSettingss),

    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_DETAILS, detailsProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.UPDATE, editProduct),

    takeLatest(SAGA_ACTIONS.ORDER.ORDER_LIST, listOrder),

    // 
    takeLatest(SAGA_ACTIONS.PRODUCT.FILE,updateFileProduct),

    takeLatest(SAGA_ACTIONS.ORDER.ORDER_DETAILS, orderDetails),

    takeLatest(SAGA_ACTIONS.COLLECTION.CUSTOMER_LIST, getCustomerList),

    takeLatest(SAGA_ACTIONS.COLLECTION.CUSTOMER_DETAILS, customerDetails),

    takeLatest(SAGA_ACTIONS.COLLECTION.CUSTOMER_DETAILS_ORDER_API, customerDetailsOrders),

    takeLatest(SAGA_ACTIONS.COLLECTION.CUSTOMER_DETAILS_PRODUCT_API, customerDetailsProducts),












  ]);
}