import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Form } from 'react-bootstrap';

interface EditorInputProps {
  value: string;
  onChange: (...args: any) => void;
  onBlur: (...args: any) => void;
  error?: any;
  inputRef?: any;
  name?: any;
  inline?: boolean;
  cssPath?:string;
  showSupportText?:boolean;
  id?:string;
  minHeight?:number;
}

export default function TinyMceEditor({
  value,
  onChange,
  onBlur,
  error,
  inputRef,
  name,
  cssPath,
  showSupportText = true,
  inline,
  minHeight,
  id
}: EditorInputProps) {
  const editorRef: any = useRef(null);
  //    const userToken = useAppUserTokenSelecto()
  return (
    <>
      <Editor
        id={id}
        ref={inputRef}
        apiKey={process.env.TINY_MCE_KEY}
        value={value}
        onEditorChange={(val: any) => {
          onChange(val);
        }}
      
        inline={inline || false}
        init={{
          content_css: cssPath,
          min_height: minHeight || 500,
          menubar: true,
          plugins: ['image code imagetools media link lists advlist'],
          toolbar:
            'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media | forecolor backcolor emoticons',
          extended_valid_elements: 'span[*]',
          convert_urls: false,
          images_upload_handler: function (blobInfo, success, failure) {
            let imgFile = new FormData();
            imgFile.append('files', blobInfo.blob(), blobInfo.filename());
            success(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`);
          },
        }}
      />
      {error && error.message ? (
        <p className="form-error">
            {error.message}
            </p>
      ) : null}
      {showSupportText && <p className="small-font float-right mt-2">
        (Images must be maximum 100kb)
      </p>}
    </>
  );
}
