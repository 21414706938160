
import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../common/appApiCallHook'


export function useCollectionApi() {

    const callApi = useApiCall()

    const getCollection = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.COLLECTION_LIST, data, onSuccess, onError);
    }
    const getCategories = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CATEGORIES.CATEGORIES_LIST, data, onSuccess, onError);
    }

    const getNetwork = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.NETWORK.NETWORK_LIST, data, onSuccess, onError);
    }

    const updateCollection = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.UPDATE, data, onSuccess, onError);
    }

    const addCollection = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.ADD, data, onSuccess, onError);
    }

    const addGenesis = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.ADD_GENESIS, data, onSuccess, onError);
    }

    const updateGenesis = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.UPDATE_GENESIS, data, onSuccess, onError);
    }

    const updateFile = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.FILE, data, onSuccess, onError);
    }

    const updateProductFile = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.PRODUCT_FILE, data, onSuccess, onError);
    }

    const previewProductFile = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.PREVIEW_FILE, data, onSuccess, onError);
    }

    const confirmBulkUpload = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.CONFIRM_BULK, data, onSuccess, onError);
    }

    const getWithoutSlugCollection = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.COLLECTION_LIST_WITHOUT_SLUG, data, onSuccess, onError);
    }

    const getStoreDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.STORE_DETAILS, data, onSuccess, onError);
    }

    const updateSettings = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.UPDATE_STORE, data, onSuccess, onError);
    }

    const addSettings = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.ADD_STORE, data, onSuccess, onError);
    }

    const customerList= (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.CUSTOMER_LIST, data, onSuccess, onError);
    }

    const customerDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.CUSTOMER_DETAILS, data, onSuccess, onError);
    }

    const customerDetailsOrderApi = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.CUSTOMER_DETAILS_ORDER_API, data, onSuccess, onError);
    }

    const customerDetailsProductApi = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COLLECTION.CUSTOMER_DETAILS_PRODUCT_API, data, onSuccess, onError);
    }

    return {
        callGetCollection: getCollection,
        callGetCategories: getCategories,
        callUpdateCollection: updateCollection,
        callUpdateFile: updateFile,
        callAddCollection: addCollection,
        callGetNetwork: getNetwork,
        callAddGenesis: addGenesis,
        callUpdateGenesis: updateGenesis,
        callUpdateProductFile: updateProductFile,
        callPreviewProductFile: previewProductFile,
        callConfirmBulkUpload: confirmBulkUpload,
        callWithoutSlugCollection: getWithoutSlugCollection,
        callStoreDetails: getStoreDetails,
        callAddSettings: addSettings,
        callUpdateSettings: updateSettings,
        callCustomerList: customerList,
        callCustomerDetails:customerDetails,
        callCustomerDetailsOrder:customerDetailsOrderApi,
        callCustomerDetailsProduct:customerDetailsProductApi,
    }
}