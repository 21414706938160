import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'src/components/common/Header'
import SettingsForm from 'src/components/settings/settingsForm'
// import Sidebar from 'src/components/common/Sidebar'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from '../../_config'


export default function SettingsUser(props:any) {

  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title
    document.title = `${APPLICATION_NAME} : Settings`
    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <React.Fragment>
      <section>
      <div className="container">
          <Header type="side" />
        </div>
        <SettingsForm slug={props?.slug} collection={props?.collection}/>
      </section>
    </React.Fragment>
  )
}
