import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';

export function* addProduct(action: any): any {
    try {
        const data = action.payload;
        console.log("AddData",data)
        const resp = yield call(CallApi.POST, `${API_URL.PRODUCT.ADD}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* listProduct(action: any): any {
    try {
        const collections_id: any = localStorage.getItem('collection_id')

        // ${data?.params?.collections_id}
        const data = action.payload;
        // console.log("data", data)
        console.log("collections_id", data.collections_id)
        const resp = yield call(CallApi.GET, `${API_URL.PRODUCT.PRODUCT_LIST}/${collections_id}`, data, true);
        // 63203ca1407abbedf3b74b53 6321d3175b356d367386cf4d
        // const resp = yield call(CallApi.GET, `${API_URL.PRODUCT.PRODUCT_LIST}/6322df31563d0769f2c1e537`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* detailsProduct(action: any): any {
    const getSlug = localStorage.getItem('slug')
    const slug = 'avijit'
    try {
        const data = action.payload;
        console.log("detailsproduct", data)
        const resp = yield call(CallApi.GET, `${API_URL.PRODUCT.PRODUCT_LIST}/${data?.contractAddress}/${data?.tokenId}?fields=categories,collectionEntity,logoImage`);
        if (resp && resp.status == 200) {
            yield put({
                type: ACTIONS.PRODUCT.PRODUCT_DETAILS,
                payload: resp.data.data
            })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* editProduct(action: any): any {
    try {
        const data = action.payload;
        console.log("editproduct", data)
        const resp = yield call(CallApi.PUT, `${API_URL.PRODUCT.UPDATE}/${data?.contractAddress}/${data?.tokenId}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* updateFileProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.PRODUCT.FILE}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}