import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { URLS, PER_PAGE } from "src/_config";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import {
  AddGroupReq,
  collectionType,
  filterUrlValues,
  OptionValue,
} from "src/_common/interfaces/common";
import { usePayoutApi } from "src/_common/hooks/actions/payout/payoutApiHook";
import Pagination from "react-js-pagination";
import * as qs from "query-string";
import queryString from "query-string";

interface Props {
  slug: string;
  collection: any;
}

interface FormValues {
  search: string;
}

const resetValue = {
  search: "",
};

const PayoutListForm = ({ slug, collection }: Props) => {
  const { control, handleSubmit, errors, reset } = useForm<any>({
    // resolver: yupResolver(CollectionSchema),
    defaultValues: resetValue,
  });

  const payoutApi = usePayoutApi();
  const toast = useToaster();
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [activePage, setactivePage] = useState<number>(1);
  const [filtersFieldShowHide, setfiltersFieldShowHide] =
    useState<boolean>(false);

  const collections_name: any = localStorage.getItem("collection_name");
  const location = useLocation();

  // useEffect(() => {
  //   getPayoutList();
  // }, []);
  // this one for filter data submit and set page 1 for pagination

  const onSubmit = (data: any) => {
    let params = { ...data };
    console.log("searchpa",data)

  params = filterUrlValues(params)

  params['page'] = 1;
  params['perPage'] = PER_PAGE;
  setactivePage(1)

  history.push(URLS.PAYOUT_LIST + `?${qs.stringify(params)}`);

  payoutApi.callListPayout(
    params,
    (message: string, resp: any) => {
      if (resp) {
        setData(resp);
      }
    },
    (message: string) => {
      toast.error(message);
    }
  );

  

  };
  




  // this one for reset  filter data 
  const resetData = () => {
    reset(resetValue)
    history.push(URLS.PAYOUT_LIST);
    let pageNumberFirstTime=1
    setactivePage(1)
    setTimeout(() => {
      getPayoutList(pageNumberFirstTime, 'reset')
    }, 500);
  };

  // if there have any params in url then it passes params through url

  useEffect(() => {
    const queryVal: any = queryString.parse(location.search);
    if (queryVal && queryVal?.page) {
      setactivePage(Number(queryVal?.page));
      reset(queryString.parse(location.search));
      console.log(queryString.parse(location.search));
      getPayoutList(queryVal?.page, "first time");
    } else {
      getPayoutList(1, "first time");
    }
  }, []);

  // pagination page change and set

  const handlePageChange = (pageNumber: any) => {
    let temp = { ...queryString.parse(location.search) };
    temp["page"] = pageNumber;
    history.push(URLS.PAYOUT_LIST + `?${qs.stringify(temp)}`);
    console.log("temp", temp);
    console.log("pageNumber", pageNumber);
    setactivePage(pageNumber);
    getPayoutList(pageNumber, "page change");
  };

  // if filter button click then show filter fields

  const filterShowHide = (flts: any) => {
    setfiltersFieldShowHide(flts);
  };
  const getPayoutList = (pageNumber: any, type: any) => {
    const params: any = {
      perPage: PER_PAGE,
    };

    if(type!='reset'){
      for (let [key, value] of Object.entries(queryString.parse(location.search))) {
        params[key] = value;
      }
    }
    params["page"] = pageNumber;

    payoutApi.callListPayout(
      params,
      (message: string, resp: any) => {
        if (resp) {
          setData(resp);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const goToAddPayout = (id: any) => {
    history.push({
      pathname: URLS.PAYOUT_ADD_EDIT,
      state: { collection: collection, slug: slug, id: id },
    });
  };


  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="information single_pro_btn common-white-box">
                <div className="c_detail ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>Payout Groups</h2>
                    <div className="d-flex align-items-center">
                      <a
                        className="btn m-2"
                        href-="#"
                        //   onClick={(e) => {
                        //     e.preventDefault();
                        //     setShowModal(true);
                        //   }}
                        onClick={() => goToAddPayout("")}
                      >
                        Add Group
                      </a>

                      <div
                        className="cursor-pointer d-flex justify-content-between align-items-baseline ms-3 flter-blue-btn"
                        onClick={() => filterShowHide(!filtersFieldShowHide)}
                      >
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
                {filtersFieldShowHide && (
                  <div className="row mb-3">
                     <div className="d-flex flex-row-reverse">
                      <div
                        className="cursor-pointer d-flex justify-content-between align-items-baseline ms-3 flter-blue-btn mb-3"
                        onClick={() => filterShowHide(!filtersFieldShowHide)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="">
                          <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="row mb-3">
                                <div className="col-lg-6 col-md-3 col-12 mt-mobile-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Search{" "}
                                    </label>
                                    <Controller
                                      defaultValue=""
                                      control={control}
                                      name="search"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          error={errors.search}
                                          placeholder="Search"
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                
                                {/* <div className="col-lg-3 col-md-3 col-12 mt-mobile-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Collection{' '}
                                </label>
                                <Controller
                                  control={control}
                                  name="endDate"
                                  render={({ onChange, onBlur, value, name, ref }) => (
                                    <AsyncSelectInput
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      options={collectionPromise}
                                      inputRef={ref}
                                      name="collection"
                                    />
                                  )}
                                />
                              </div>
                            </div> */}
                              </div>
                              <div className="d-flex justify-content-end mb-4 mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary btnclours mx-2"
                                >
                                  Filter
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger btnclours"
                                  onClick={resetData}
                                >
                                  Clear
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="table-responsive pro_table tableFixHead">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>
                          {/* <i
                          className="fa fa-refresh cursor-pointer"
                        /> */}
                        </th>
                        <th>Name</th>
                        <th>Number of payees</th>
                        <th>Collection</th>
                        <th>Collection Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    {data && data?.total > 0 ? (
                      data?.data?.map((item: any, index: number) => {
                        return (
                          <tbody key={item._id}>
                            <tr>
                              <td></td>
                              <td>{item?.name}</td>
                              <td>{item?.payees?.length}</td>

                              <td>
                                {/* {item?.collection?.name || 'N.A'} */}
                                {collections_name ? collections_name : "N.A"}
                              </td>

                              <td>
                                {collectionType(
                                  item?.collection?.contractCollectionType
                                )}
                                {/* {collections_name? collections_name : 'N.A'} */}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn-blue "
                                  title="Edit"
                                  onClick={() => {
                                    // setRowId(item.id);
                                    // setShowModal(true);
                                    goToAddPayout(item?._id);
                                  }}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                {/* <button
                              type="button"
                              className="btn-blue "
                              title="Delete"
                              onClick={() => deleteGroup(item.id)}
                            >
                              <i className="fa fa-trash" />
                            </button> */}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={9} align="center">
                            No results found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {data && data?.total > 0 ? (
                <div className="d-flex justify-content-end mt-3">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={PER_PAGE}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    innerClass="pagination mt-3 font-link"
                    itemClass="paginate_button page-item"
                    linkClass="page-link"
                    activeClass="active"
                    hideFirstLastPages={true}
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* {showModal && (
        <PayoutGroupModal show={true} close={closeModal} id={rowId} />
      )} */}
      </section>

      {/* </form> */}
    </>
  );
};

export default PayoutListForm;
