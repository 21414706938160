import React, { useState, useRef, forwardRef, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  AddPropertyFormProps,
  PropertyType,
} from "../../_common/interfaces/ProductManage";
import { Button } from "react-bootstrap";

const defaultVal: PropertyType = { key: "", value: "" };

export const productGroupSchema = yup.object().shape({
  properties: yup.array().of(
    yup.object().shape(
      {
        key: yup.string().when("value", {
          is: true,
          then: yup.string().min(1).required("Type is required"),
        }),
        value: yup.string().when("key", {
          is: true,
          then: yup.string().min(1).required("Name is required"),
        }),
      },
      [["key", "value"]]
    )
  ),
});

const AddPropertyForm = forwardRef((props: AddPropertyFormProps, ref) => {
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(productGroupSchema),
    mode: "onBlur",
  });
  const { defaultProperties, setPropertiesValueHandler, successHandler } =
    props;

  const [properties, setProperties] = useState<PropertyType[]>(
    defaultProperties?.length ? [...defaultProperties] : [defaultVal]
  );
  let { properties: formProperties }: any = watch();

  useEffect(() => {
    reset({ properties: properties });
  }, [properties]);

  const removePropertiesHandler = (selectedIndex: number) => {
    let tempFormProperties =
      formProperties && formProperties.length
        ? [...formProperties]
        : [defaultVal];

    if (tempFormProperties[selectedIndex]) {
      tempFormProperties.splice(selectedIndex, 1);
    }

    setProperties(tempFormProperties);
  };

  const addMoreHandler = () => {
    let tempFormProperties =
      formProperties && formProperties.length
        ? [...formProperties]
        : [defaultVal];
    tempFormProperties.push(defaultVal);
    setProperties(tempFormProperties);
  };

  const onSubmit = (values: any) => {
    let { properties: submittedProperties } = values;
    submittedProperties = submittedProperties.filter(
      (item: PropertyType) => item?.key && item?.value
    );
    setPropertiesValueHandler && setPropertiesValueHandler(submittedProperties);
    successHandler && successHandler();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end mt-4">
          <button
            type="button"
            className="btn btn-primary ms-3"
            onClick={() => addMoreHandler()}
            title="Add more"
          >
            <i className="fa fa-plus" />
          </button>
        </div>

        <div
          className="table-responsive pro_table tableFixHead mt-4"
          style={{
            maxHeight: "300px",
            overflow: "auto",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((val: PropertyType, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <Controller
                        defaultValue={val.key ? val.key : ""}
                        control={control}
                        name={`properties.${index}.key`}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <FormTextInput
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            type="text"
                            placeholder="Enter Type"
                            error={errors?.properties?.[index]?.key}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        defaultValue={val.key ? val.key : ""}
                        control={control}
                        name={`properties.${index}.value`}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <FormTextInput
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            type="text"
                            placeholder="Enter name"
                            error={errors?.properties?.[index]?.value}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <div className="btn-group btn-block">
                        {properties.length > 1 ? (
                          <button
                            type="button"
                            className="btn-red "
                            title="Delete"
                            onClick={() => removePropertiesHandler(index)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        ) : null}
                        <button
                          type="button"
                          className="btn-green "
                          title="Add More"
                          onClick={() => addMoreHandler()}
                        >
                          <i className="fa fa-plus" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </div>
        </div>
      </form>
    </>
  );
});

export default AddPropertyForm;
