import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import GenesisForm from 'src/components/genesis/genesisForm'
// import Sidebar from 'src/components/common/Sidebar'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from '../../_config'


export default function GenesisUser(props:any) {

  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title
    document.title = `${APPLICATION_NAME} : Genesis`
    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <React.Fragment>
      <section>
        <GenesisForm slug={props?.slug} collection={props?.collection}/>
      </section>
    </React.Fragment>
  )
}
