import React from 'react';

interface SwitchInputProps {
  onChange: (...args: any) => void;
  value: string | boolean;
  inputRef?: any;
  name?: string;
  id: string;
  label: string;
  isDisabled?:boolean;
}

function SwitchInput({
  onChange,
  value,
  inputRef,
  name,
  id,
  label,
  isDisabled
}: SwitchInputProps) {
  return (
    <div className="form-check form-switch form-check-new">
      <label
        className="form-check-label"
        htmlFor={id}
      >{label}</label>
      <input
        className="form-check-input"
        type="checkbox"
        name={name}
        disabled={isDisabled}
        id={id}
        onChange={(e) => onChange(e.target.checked)}
        ref={inputRef}
        checked={Boolean(value) === true}
      />
    </div>
  );
}

export default SwitchInput;