import { call } from 'redux-saga/effects';
import { API_URL, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

// export function* getGenderList(action: any): any {
//   try {
//     const data = action.payload;
//     const resp = yield call(CallApi.GET, API_URL.COMMON.GENDER_LIST, data, false);

//     if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

//       action && action.callbackSuccess && action.callbackSuccess(resp.data);
//     } else {
//       action && action.callbackError && action.callbackError(resp.data);
//     }
//   } catch (e) {
//     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//   }
// }


export function* getToken(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COMMON.TOKEN}`, data?.parms, true);
        console.log('resp token', resp.data.data.token)
        if (resp && resp.status==200) {
            localStorage.setItem(STORAGE, resp.data.data.token);
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}