
import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../common/appApiCallHook'


export function usePayoutApi() {

    const callApi = useApiCall()

    const addPayout = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PAYOUT.ADD, data, onSuccess, onError);
    }
    const listPayout = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PAYOUT.PAYOUT_LIST, data, onSuccess, onError);
    }
    const payoutDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PAYOUT.PAYOUT_DETAILS, data, onSuccess, onError);
    }
    const editPayout = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PAYOUT.UPDATE, data, onSuccess, onError);
    }

    const addStatusChangeForCollection = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PAYOUT.PAYOUT_STATUS, data, onSuccess, onError);
    }

    return {
        callAddPayout: addPayout,
        callListPayout: listPayout,
        callPayoutDetails: payoutDetails,
        callEditPayout: editPayout,
        callPayoutStatus: addStatusChangeForCollection,
    }
}