import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* loginUser(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // localStorage.setItem(STORAGE, resp.data.data.token);
      // yield put({
      //   type: ACTIONS.USER.LOGIN,
      //   payload: {
      //     user: resp.data.data.user,
      //     token: resp.data.data.token,
      //   }
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* loginUserWithOtp(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN_WITH_OTP, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          token: resp.data.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* logoutUser(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGOUT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      localStorage.removeItem(STORAGE)
      yield put({
        type: ACTIONS.USER.LOGOUT,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateUserProfile(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.UPDATE_PROFILE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getUserProfile(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.USER.DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data.user
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* registration(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.REGISTRATION, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      localStorage.setItem(STORAGE, resp.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data,
          token: resp.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* forgotPassword(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* resetPassword(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* loginUserWithEmail(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN_WITH_EMAIL, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          token: resp.data.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}