import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import SwitchInput from "src/_common/components/form-elements/switchInput";
import DateInput from "src/_common/components/form-elements/dateInput";
import FormTexAreatInput from "src/_common/components/form-elements/texareaInput";
import { useCollectionApi } from "src/_common/hooks/actions/collection/collectionApiHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import moment from 'moment';



export interface CollectionGenesisdropReq {
  status: any;
  startDate: any;
  quantity: number;
  endDate: any;
  price: number;
  description: string;
}

interface Props {
  slug: string;
  collection: any;
}


const CollectionSchema = yup.object().shape({
  

});

const GenesisForm = ({ slug, collection }: Props) => {
  const { control, handleSubmit, errors, reset } = useForm<CollectionGenesisdropReq>({
    resolver: yupResolver(CollectionSchema),
    defaultValues: {
      
    },
  });

  const collectionApi = useCollectionApi();
  const toast = useToaster();




  useEffect(() => {
    console.log('collections', collection)
    console.log('slug', slug)

    let collectionDetails = collection && collection?.length && collection?.find(
      (item: any) => item.eventType === 'genesis',
    );
    console.log('collectionDetails', collectionDetails)
    if (collectionDetails) {
      reset({
        startDate: new Date(collectionDetails.startDate),
        endDate: new Date(collectionDetails.endDate),
        price: collectionDetails.price,
        quantity: collectionDetails.quantity,
        description:collectionDetails.description,
        status: collectionDetails.status === 'active' ? true : false,
      });
    }
  }, [collection]);

  const updateDrop = (values: CollectionGenesisdropReq) => {
    let params: any = { ...values };
    params['startDate'] = moment.utc(values.startDate).format();
    params['endDate'] = moment.utc(values.endDate).format();
    params['status'] = values.status ? 'active' : 'inactive';
    params['price'] = Number(values.price);
    params['quantity'] = Number(values.quantity);
    if(collection && collection?.length && collection?.length!=0){
      params['collection_slug']=slug? slug : ''
      genesisUpdate(params)
    }
    else{
      params['collection_slug']=slug? slug : ''
      genesisAdd(params)
    }
    console.log('values', values)
  };


  const genesisUpdate = (parms:any) => {
    collectionApi.callUpdateGenesis(
    parms,
    (message: string, resp: any) => {
      if (resp) {
        toast.success(message);
        
      } else {
        toast.error(message);
      }
    },
    (message: string) => {
      toast.error(message);
    }
  );
  }

  // <--------------this one for collection add--------------------->


  const genesisAdd = (parms:any) => {
    collectionApi.callAddGenesis(
    parms,
    (message: string, resp: any) => {
      if (resp) {
        toast.success(message);
        
      } else {
        toast.error(message);
      }
    },
    (message: string) => {
      toast.error(message);
    }
  );
  }


  return (
    // <form>
    <>
    <div className="mt-4">
      <div className="payment_card px-0">
        <div className="login-text">
          <form onSubmit={handleSubmit(updateDrop)}>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="form-group">
                  <Controller
                    defaultValue=""
                    control={control}
                    name="status"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <SwitchInput
                        onChange={onChange}
                        name={name}
                        value={value}
                        inputRef={ref}
                        id={name}
                        label={'Enable'}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Start Date<span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <DateInput
                        inputRef={ref}
                        name={name}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        placeholder="Start Date"
                        showTimeSelect={true}
                        dateFormat="MM-dd-yyyy hh:mm a"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    End Date<span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <DateInput
                        inputRef={ref}
                        name={name}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        placeholder="End Date"
                        showTimeSelect={true}
                        dateFormat="MM-dd-yyyy hh:mm a"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="login-text ">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Price{' '}
                      {/* {currencyUnitWords(
                        networkSelector(collection?.networkId)?.network,
                      )} */}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      defaultValue=""
                      control={control}
                      name="price"
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <FormTextInput
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          inputRef={ref}
                          type="text"
                          error={errors.price}
                          placeholder="Enter Price"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="login-text ">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Total Supply <span className="text-danger">*</span>
                    </label>
                    <Controller
                      defaultValue=""
                      control={control}
                      name="quantity"
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <FormTextInput
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          inputRef={ref}
                          type="text"
                          error={errors.quantity}
                          placeholder="Enter Quantity"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Description </label>
                  <Controller
                    defaultValue=""
                    control={control}
                    name="description"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <FormTexAreatInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type="text"
                        rows={5}
                        error={errors.description}
                        placeholder=""
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <button type="submit" className="btn btn-primary ml-0">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <SwitchNetModal propsFn={makeContractCall} /> */}
    </div>

      {/* </form> */}
    </>
  );
}

export default GenesisForm;
