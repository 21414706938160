import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'src/components/common/Header';
import ProductForm from 'src/components/product/productForm';
import ProductTabForm from 'src/components/product/productTabForm';
import PayoutTabForm from '../../components/payout/payoutTabForm'
// import Sidebar from 'src/components/common/Sidebar'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from '../../_config'

export default function ProductTab(props:any) {

  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title
    document.title = `${APPLICATION_NAME} : Products`
    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <React.Fragment>
      <section>
      <div className="container">
            
            <Header type='side' />
       
   </div>
        <ProductForm slug={props?.slug} collection={props?.collection}/>
      </section>
    </React.Fragment>
  )
}
