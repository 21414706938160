import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCollectionListSelector } from "src/_common/hooks/selectors/eventSelector";
import { URLS } from "src/_config";
interface HeaderProps {
  type?: string;
}

function Header({ type }: HeaderProps) {
  const slugs =localStorage.getItem('slug')
  const useCollectionListsSelector: any = useCollectionListSelector();
  console.log(useCollectionListsSelector?.collection?.slug)

  
  return (
    <React.Fragment>
      <div className="lite-logo">
        <div className="row">
          <div className="col-6">
            {type && type == "full" ? (
              <div className="event-logo">
                <Link to={URLS.COLLECTION} className="back-history">
                  <img src="images/shopify.png" alt="#" />
                </Link>
              </div>
            ) : (
              <div className="lite-logo-in">
                <Link to={URLS.COLLECTION} className="back-history">
                  <img src="../images/shopify.png" alt="#" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <nav className="navbar navbar-expand-lg">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-4 bgnone">
                <li className="nav-item">
                  <Link to={URLS.COLLECTION} className="back-history nav-link">
                    Collection
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={URLS.PAYOUT_LIST} className="back-history nav-link">
                    Payout
                  </Link>
                </li>
                {useCollectionListsSelector && useCollectionListsSelector?.collection?.slug && 
                <li className="nav-item">
                <Link to={URLS.PRODUCT_LIST} className="back-history nav-link">
                  Products
                </Link>
              </li>
                }
                <li className="nav-item">
                  <Link to={URLS.ORDER_LIST} className="back-history nav-link">
                    Orders
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={URLS.CUSTOMER} className="back-history nav-link">
                    Customer
                  </Link>
                </li>
                
                <li className="nav-item">
                  <Link to={URLS.SETTINGS} className="back-history nav-link">
                    Settings
                  </Link>
                </li>

                
              
                {/* <li className="nav-item">
                  <Link to={URLS.UPLOAD_PRODUCT} className="back-history nav-link">
                    Upload Product
                  </Link>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dropdown
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
