import { StylesConfig } from 'react-select';

export const styles: StylesConfig<any, any> = {
  container: (provided, state) => ({
    ...provided,
    fontSize: '1rem',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: '1rem',
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontSize: '1rem',
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};
