import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useCollectionApi } from "src/_common/hooks/actions/collection/collectionApiHook";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import { CONTRACT_TYPE, OptionValue } from "src/_common/interfaces/common";

import FormTexAreatInput from "src/_common/components/form-elements/texareaInput";
import { useCollectionListSelector } from "src/_common/hooks/selectors/eventSelector";
import SwitchInput from "src/_common/components/form-elements/switchInput";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import AsyncSelectInput from "src/_common/components/form-elements/asyncSelect";
import { useHistory } from "react-router";
import { API_URL, STORAGE, URLS } from "src/_config";
import axios from "axios";
import { CallApi } from "src/sagas/api/callApi";
import { Modal } from 'react-bootstrap';
import { usePayoutApi } from "src/_common/hooks/actions/payout/payoutApiHook";
import { useAppLoaderSelector } from "src/_common/hooks/selectors/loaderSelector";
import { useAppLoader } from 'src/_common/hooks/actions/common/appLoaderHook'
import CsvDownload from 'react-json-to-csv'



export interface UploadCSVProps {
  uploadCSV: (fd: FormData) => void;
}



const UploadProduct = () => {
  const inputRef: any = React.useRef();
  const dispatch = useDispatch();
  const toaster = useToaster();

  const [name, setName] = useState<string>('');
  const [fileStore, setFile] = useState<null | File>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] = useState<OptionValue>();
  const [selectedGroup, setSelectedGroup] = useState<OptionValue>();
  const collections_id:any=localStorage.getItem('collection_id')
  const collections_name:any=localStorage.getItem('collection_name')
  const [collectionItem, setCollectionItem] = useState<any>(null);
  const [errorData, setErrorData] = useState<any>(null);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  
  const collectionApi = useCollectionApi();
  const history = useHistory();
  const toast = useToaster();
  const payoutApi = usePayoutApi();

  const { showLoader, hideLoader } = useAppLoader()


  useEffect(() => {
    hideLoader()
  }, [])
  


  const handleUpload = (file: File) => {
    if (file) {
      setName(file.name);
      setFile(file);
    }
    (document as any).getElementById('file').value = '';
    console.log('file.name', file.name)
    console.log('file', file)
    setErrorStatus(false)
  };

  const performUpload = () => {
    if (!collections_id || !fileStore) {
      toaster.error('Please select a collection and file');
    } else {
      let formdata: FormData = new FormData();
      formdata.append('file', fileStore);
      formdata.append('collectionEntity', collections_id ? collections_id : null);
      formdata.append('contractAddress', '0x63ef98f31c732a54ab4be26434a914e81da33990');
      uploadCSV(formdata);
    }
  };

  const onClose= (parms:any) =>{
    setShowModal(false)
  }


  const uploadCSV = (parms:any) => {
  //   collectionApi.callUpdateProductFile(
  //   parms,
  //   (message: string, resp: any) => {
  //     if (resp) {
  //       console.log('resp', resp)
  //       toast.success(message);
  //       history.push({
  //         pathname: URLS.PREVIEW_UPLOAD,
  //         state: { jobId: resp?.jobId},
  //       });      
  //     } else {
  //       toast.error(message);
  //     }
  //   },
  //   (message: string) => {
  //     toast.error(message);
  //   }
  // );

  try {
    let customHeaderName = 'x-store-id'
    const token = localStorage.getItem(STORAGE);
    axios({
      method: 'POST',
      url: API_URL.COLLECTION.PRODUCT_FILE,
      data: parms,
      headers: {
        [customHeaderName]: 'shopify',
        Authorization: `Bearer ${token}`,
        },
    }).then((success:any) => {
        console.log('success', success)
        toast.success(success?.data?.message);
        history.push({
          pathname: URLS.PREVIEW_UPLOAD,
          state: { jobId: success?.data?.data?.jobId},
        }); 
        
      })
      .catch((error) => {
        // Error
        if (error.response) {
            console.log(error.response.data);
            console.log(error?.response?.data?.message);
            toaster.error(error?.response?.data?.message);
            setErrorData(error?.response?.data?.error)
            // setShowModal(true)
            if( error?.response?.data?.status == '422' && error?.response?.data?.success==false){
            setErrorStatus(true)
            }
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
  } catch (e) {
    console.log(e)
  }
  }



  useEffect(() => {

    if (collections_id) {
      let collections_details = {
        label: collections_name,
        value: collections_id,
      };
      setCollectionItem(collections_details);
    }
  }, [collections_name,collections_id]);

  

  

  // <--------------this one for fav icon upload--------------------->

  // <--------------this one for network list--------------------->

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="information multiple_sec common-white-box">
              <h2>Import Products</h2>
              <div className="col-12">
                <p>
                  Upload CSV file and import products in batch. 
                </p>
                <div
                  className="upload_icon cursor-pointer"
                  onClick={() => inputRef?.current.click()}
                >
                  <img
                    src={`../images/upload.png`}
                    alt="#"
                  />
                  <h4>{'Upload batch products'}</h4>
                </div>
                <input
                  id="file"
                  type="file"
                  hidden
                  ref={inputRef}
                  onChange={(e: any) => handleUpload(e.target.files[0])}
                />
                <div className="mx-auto w-50">
                <AsyncSelectInput
                          onChange={(val) => {
                            // setAddCollection(val);
                          }}
                          value={collectionItem}
                          options={collectionItem}
                          isClearable={false}
                          placeholder={`Enter Collection name`}
                          isDisabled={collections_id}
                        />
                </div>
                {fileStore && !errorStatus && (
                  <button
                    type="button"
                    className="btn mt-4"
                    onClick={performUpload}
                  >
                    Continue
                  </button>
                )}

                {
                  errorStatus && 
                  (
                    <CsvDownload
                            data={errorData}
                            filename="errorLogs.csv"
                            className="btn btn-dark mt-3"
                          >
                            Download Error Logs
                    </CsvDownload>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MultipleUploadHelp show={showModal} close={() => setShowModal(false)} /> */}
    </section>
{showModal && 
    <React.Fragment>
            <Modal
                show={true}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
               
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        <h4 className="rider-name-content">
                        {/* {errorData && errorData.length!=0 && errorData.map((item:any, index:any) => (
                            <p key={index} className="text-color-chng">
                               {index+1}. {item?.category}
                            </p>
                        ))} */}
                        </h4>
                        <CsvDownload
                            data={errorData}
                            filename="good_data.csv"
                            className="btn btn-dark mt-3"
                          >
                            Download Error Logs
                          </CsvDownload>
                        {/* <button className="btn btn-dark mt-3" onClick={() => onClose(true)}>
                        Close
                        </button> */}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                        <button
                    type="button"
                    className="btn mt-4"
                    onClick={() => onClose(true)}
                  >
                    Close
                  </button>
                </Modal.Footer> */}
            </Modal>
        </React.Fragment>
}

      {/* </form> */}
    </>
  );
}

export default UploadProduct;
