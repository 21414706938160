import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import {
  URLS,
} from "src/_config";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import SwitchInput from "src/_common/components/form-elements/switchInput";
import { AddGroupReq, OptionValue } from "src/_common/interfaces/common";
import AsyncSelectInput from "src/_common/components/form-elements/asyncSelect";
import ConfirmAlert from "src/_common/components/form-elements/confirmAlert";
import { usePayoutApi } from "src/_common/hooks/actions/payout/payoutApiHook";

interface PayoutFormValues {
  email: string;
  password: string;
  name: string;
  url: string;
  slug: string;
  description: string;
  link: any;
  price: number;
  quantity: number;
}

const blank_group = {
  name: "",
  email: "",
  cryptoAddress: "",
  percentageFees: 0,
};

interface Props {
  slug: string;
  collection: any;
}

export const payoutGroupSchema = yup.object().shape({
  name: yup.string().required('Group name is required'),
  payees: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        email: yup.string().email('Invalid email').nullable(),
        cryptoAddress: yup.string().required('Wallet required'),
        percentageFees: yup
          .number()
          .min(0, 'Minimum must be 0')
          .required('Percentage is required')
          .typeError('Invalid Number'),
      }),
    )
    .required(),
});

const PayoutForm = ({ slug, collection }: Props) => {
  const { control, handleSubmit, errors, reset } = useForm<any>({
    resolver: yupResolver(payoutGroupSchema),
    defaultValues: {
      payees: [blank_group],
    },
  });

  const [groupPromise, setgroupPromise] = useState<any>(null);
  const [collectionItem, setCollectionItem] = useState<any>(null);

  const [selectedGroup, setGroup] = useState<any>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "payees",
  });
  const payoutApi = usePayoutApi();
  const toast = useToaster();
  const location:any = useLocation();
  const collections_id:any=localStorage.getItem('collection_id')
  const collections_name:any=localStorage.getItem('collection_name')

  const slugs=localStorage.getItem('slug')
  const history = useHistory();


  useEffect(() => {
    let loc: any = location;
    console.log("loc",loc)
    if(loc && loc?.state?.id){
      getPayOutDetails()
    }
  }, [location]);

  useEffect(() => {

    if (collections_id) {
      let collections_details = {
        label: collections_name,
        value: collections_id,
      };
      setCollectionItem(collections_details);
    }
  }, [collections_name,collections_id]);





  const removeRow = (index: number) => {
    ConfirmAlert({
      title: "Are you sure?",
      message: "This row will be deleted",
      onConfirm: () => {
        remove(index);
      },
    });
  };

  const getPayOutDetails = () => {
    let loc: any = location;
    console.log("locfunction",loc)

    const params:any = { 
      id: loc && loc?.state?.id
    };

    payoutApi.callPayoutDetails(
      params,
      (message: string, resp: any) => {
        let { data } = resp;
        reset({
          name: resp && resp.name ? resp.name : 'abc',
          payees: resp && resp.payees && resp.payees.length ? resp.payees : [],
          isDefault: resp && resp.status=='active' ? true :  false,
        });
        // setColloectionListValue(resp.data[0])
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const handleSubmitForm = (data: AddGroupReq) => {
    let loc: any = location;
    let params: any = { ...data };
    params["collectionEntity"] = collections_id ? collections_id: null
    if(loc && loc?.state?.id){
    params["payoutId"]=loc?.state?.id
    payoutApi.callEditPayout(
      params,
      (message: string, resp: any) => {
        if (resp) {
          toast.success(message);
          history.push({
            pathname: URLS.PAYOUT_LIST,
          });
        } else {
          toast.error(message);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
    }
    else{
      payoutApi.callAddPayout(
        params,
        (message: string, resp: any) => {
          if (resp) {
            toast.success(message);
            history.push({
              pathname: URLS.PAYOUT_LIST,
            });
          } else {
            toast.error(message);
          }
        },
        (message: string) => {
          toast.error(message);
        }
      );

    }
  };
  
  console.log("fields",fields)

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="information get_start tab-sec-n settings-custom-wrapper">
                <div className="row">
                  <div className="col-12 px-3">
                    <div className="page-title-box d-flex align-items-center justify-content-between"></div>
                    <form>
                      <div className="form-group mb-3">
                        <h6>Collection</h6>
                        <h6>{/* <strong>{addCollection?.label}</strong> */}</h6>
                        <AsyncSelectInput
                          onChange={(val) => {
                            // setAddCollection(val);
                          }}
                          value={collectionItem}
                          options={collectionItem}
                          isClearable={false}
                          placeholder={`Enter Collection name`}
                          isDisabled={collections_id}
                        />
                      </div>

                      {/* {addCollection && ( */}
                      <>
                        <div className="form-group">
                          <h6>Payout group name</h6>
                          <Controller
                            defaultValue={""}
                            control={control}
                            name="name"
                            render={({
                              onChange,
                              onBlur,
                              value,
                              name,
                              ref,
                            }) => (
                              <FormTextInput
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                type="text"
                                error={errors.name}
                                placeholder="Enter Name"
                              />
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <Controller
                            defaultValue={false}
                            control={control}
                            name="isDefault"
                            render={({
                              onChange,
                              onBlur,
                              value,
                              name,
                              ref,
                            }) => (
                              <SwitchInput
                                onChange={onChange}
                                name={name}
                                value={value}
                                inputRef={ref}
                                id={name}
                                label={"Make it default"}
                              />
                            )}
                          />
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                          <button
                            type="button"
                            className="btn btn-primary ms-3"
                            onClick={() => append(blank_group)}
                          >
                            Add More
                          </button>
                        </div>
                        <div
                          className="table-responsive pro_table tableFixHead mt-4"
                          style={{ maxHeight: "300px", overflow: "auto" }}
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Percent</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields && fields.length!=0 && fields.map((item, index) => (
                                <tr key={item.id}>
                                  <td>
                                    <Controller
                                      defaultValue={item.name ?  item.name : ''}
                                      control={control}
                                      name={`payees.${index}.name`}
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          placeholder="Enter name"
                                        />
                                      )}
                                    />
                                    <h6 className="text-danger mt-1">
                                      {/* {errors?.payees?.[index]?.name?.message} */}
                                    </h6>
                                  </td>
                                  <td>
                                    <Controller
                                      defaultValue={item.email ?  item.email : ''}
                                      control={control}
                                      name={`payees.${index}.email`}
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          placeholder="Enter email"
                                        />
                                      )}
                                    />
                                    <h6 className="text-danger mt-1">
                                      {/* {errors?.payees?.[index]?.email?.message} */}
                                    </h6>
                                  </td>
                                  <td>
                                    <Controller
                                      defaultValue={item.cryptoAddress ?  item.cryptoAddress : ''}
                                      control={control}
                                      name={`payees.${index}.cryptoAddress`}
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          placeholder="Enter wallet"
                                        />
                                      )}
                                    />
                                    <h6 className="text-danger mt-1">
                                      {/* {errors?.payees?.[index]?.cryptoAddress?.message} */}
                                    </h6>
                                  </td>
                                  <td>
                                    <Controller
                                      defaultValue={item.percentageFees ?  item.percentageFees : ''}
                                      control={control}
                                      name={`payees.${index}.percentageFees`}
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="number"
                                          placeholder="Enter percentage"
                                        />
                                      )}
                                    />
                                    <h6 className="text-danger mt-1">
                                      {/* {errors?.payees?.[index]?.percentageFees?.message} */}
                                    </h6>
                                  </td>
                                  <td>
                                    {fields.length > 1 && (
                                      <button
                                        type="button"
                                        className="btn-red "
                                        title="Delete"
                                        onClick={() => removeRow(index)}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="d-flex justify-content-end mt-2">
                          <button
                            type="button"
                            className="btn btn-success ms-2"
                            onClick={handleSubmit((d: any) =>
                              {console.log("d",d)
                                handleSubmitForm(d)
                              }
                            )}
                          >
                            {location && location?.state?.id? 'Update' : 'Submit'}
                          </button>
                          {/*  <button
                    type="button"
                    className="btn btn-success ms-2"
                    onClick={handleSubmit((d) => onSubmitAndSync(d))}
                  >
                    Save & Sync
                  </button> */}
                        </div>
                      </>
                      {/* // )} */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* </form> */}
    </>
  );
};

export default PayoutForm;
