import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import CollectionForm from 'src/components/collection/collectionForm'
import Header from 'src/components/common/Header'
// import Sidebar from 'src/components/common/Sidebar'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from '../../_config'

export default function CollectionUser() {

  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title
    document.title = `${APPLICATION_NAME} : Collection`
    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <React.Fragment>
      <section>
      <div className="container">
            
                         <Header type='side' />
                    
                </div>
        <CollectionForm />
      </section>
    </React.Fragment>
  )
}
