import React from 'react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap'
import { styles } from './styles';
import { OptionValue } from 'src/_common/interfaces/common';


interface SelectInputProps {
  onChange: (...args: any) => void;
  onBlur?: () => void;
  // options: OptionValue[];
  options: (input: any, callback: any) => void
  value: OptionValue| undefined | string;
  name?: string;
  inputRef?: any;
  placeholder?: string;
  error?: any;
  className?: string;
  id?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  dark?: boolean;
  isDisabled?:boolean;
  menuIsOpen?: boolean;
  noOptionsMessage?: string;
}

function AsyncSelectInput({
  onChange,
  onBlur,
  value,
  name,
  inputRef,
  isDisabled,
  placeholder,
  error,
  id,
  className,
  options,
  isSearchable,
  isClearable,
  menuIsOpen,
  noOptionsMessage,
  dark,
}: SelectInputProps) {

  return (
    <div>
      <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={options}
        value={value}
        onChange={onChange}
        isClearable={isClearable == undefined ? true : isClearable}
        onBlur={onBlur}
        styles={styles}
        name={name}
        isDisabled={isDisabled}
        ref={inputRef}
        className={className}
        placeholder={placeholder}
        components={{ IndicatorSeparator: () => null }}
        noOptionsMessage={() => noOptionsMessage ? noOptionsMessage : 'No Data Available'}
      />
      {error && error.message ? (
                <p className="form-error">
                    {error.message}
                </p>
            ) : null}
    </div>
  )
}

export default AsyncSelectInput