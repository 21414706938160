import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useCollectionApi } from "src/_common/hooks/actions/collection/collectionApiHook";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import { CONTRACT_TYPE, convertStatus, currencyUnit, formatDate, formatTime, OptionValue } from "src/_common/interfaces/common";

import FormTexAreatInput from "src/_common/components/form-elements/texareaInput";
import { useCollectionListSelector } from "src/_common/hooks/selectors/eventSelector";
import SwitchInput from "src/_common/components/form-elements/switchInput";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import AsyncSelectInput from "src/_common/components/form-elements/asyncSelect";
import { useHistory, useLocation } from "react-router";
import { URLS } from "src/_config";
import ConfirmAlert from "src/_common/components/form-elements/confirmAlert";
import { useAppLoader } from 'src/_common/hooks/actions/common/appLoaderHook'


export interface UploadCSVProps {
  uploadCSV: (fd: FormData) => void;
}

interface UploadState {
  processedProducts: number;
  erroredProducts: number;
  totalProducts: number;
}

const PreviewUpload = () => {
  const inputRef: any = React.useRef();
  const dispatch = useDispatch();
  const toaster = useToaster();

  const [name, setName] = useState<string>('');
  const [fileStore, setFile] = useState<null | File>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] = useState<OptionValue>();
  const [selectedGroup, setSelectedGroup] = useState<OptionValue>();
  const collections_id:any=localStorage.getItem('collection_id')
  const collections_name:any=localStorage.getItem('collection_name')
  const [collectionItem, setCollectionItem] = useState<any>(null);
  const [showUploadBar, setShowUploadBar] = useState<Boolean>(true);
  const [showMessage, setShowMessage] = useState<Boolean>(false);
  const [data, setDataItem] = useState<any>(null);
  const history = useHistory();
  const location:any = useLocation();
  const { showLoader, hideLoader } = useAppLoader()




  const collectionApi = useCollectionApi();
  const toast = useToaster();
  const [uploadState, setUploadState] = useState<UploadState>({
    processedProducts: 0,
    erroredProducts: 0,
    totalProducts: 0,
  });

  


  const previewProductFile = () => {
    const parms={jobId: location && location?.state?.jobId ? location?.state?.jobId : '', collection_id: collections_id}
    console.log('parms', parms)
    collectionApi.callPreviewProductFile(
    {parms},
    (message: string, resp: any) => {
      if (resp) {
        console.log('preview Response', resp)
        setDataItem(resp?.data)
        // toast.success(message);        
      } else {
        toast.error(message);
      }
    },
    (message: string) => {
      // toast.error(message);
    }
  );
  }

  const confirmBulkUpload = () => {
    const parms:any={jobId: location && location?.state?.jobId ? location?.state?.jobId : '', collection_id: collections_id}
    parms['confirm']=true
    console.log('parms', parms)
    collectionApi.callConfirmBulkUpload(
    {parms},
    (message: string, resp: any) => {
      console.log('gullu', resp)
      if (resp) {
        console.log('preview Response', resp)
        if(resp && resp?.jobId){
          setShowMessage(true)
        }
        toast.success(message);        
      } else {
        toast.error(message);
      }
    },
    (message: string) => {
      toast.error(message);
    }
  );
  }
  const confirmMessage = () => {
  ConfirmAlert({
    title: "Are you sure?",
    message: "You want to confirm",
    onConfirm: () => {
      confirmBulkUpload()
    },
  });
}

  useEffect(() => {
    if(location && location?.state?.jobId){
    previewProductFile();
    }
    else{
    hideLoader();
    }
  }, []);

  useEffect(() => {
    let loc: any = location;
    if(loc && loc?.state?.jobId){
      console.log('jobId', loc?.state?.jobId)
    }
  }, [location]);

  const goToProductUploadPage = () => {
    history.push(URLS.UPLOAD_PRODUCT)
  }


  const _downloadCSV = () => {
    //create and download csv
    const parms:any={jobId: location && location?.state?.jobId ? location?.state?.jobId : '', collection_id: collections_id}
    collectionApi.callConfirmBulkUpload(
    {parms},
    (message: string, resp: any) => {
      if (resp) {
        let csvData = new Blob([resp], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);

        let hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = location?.state?.jobId + '.csv';
        hiddenElement.click();
        hiddenElement.remove();       
      } else {
        // toast.error(message);
      }
    },
    (message: string) => {
      // toast.error(message);
    }
  );
  };


  

  

  // <--------------this one for fav icon upload--------------------->

  // <--------------this one for network list--------------------->

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="information multiple_sec pro_btn">
              <h2>
                <i
                  className="fa fa-chevron-left back-arrow"
                  title="Back to upload CSV"
                  onClick={() =>
                    goToProductUploadPage()
                  }
                />{' '}
                Preview uploaded products
              </h2>
              {/* {showUploadBar && ( */}
                {/* <div className="inner_mod_transfer mt-2">
                  <p className="mb-1">
                    Monitor real-time product upload status
                  </p>
                  <div className="progress_bar">
                    <div className="row">
                      <div className="col-6">
                        <span>
                          {uploadState.processedProducts} processed ,{' '}
                          {uploadState.erroredProducts} error{' '}
                        </span>
                      </div>
                      <div className="col-6 text-end">
                        <span>
                          {uploadState.processedProducts} /{' '}
                          {uploadState.totalProducts}{' '}
                        </span>
                      </div>
                      <div className="col-12">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${
                                (uploadState.processedProducts /
                                  uploadState.totalProducts || 0) * 100
                              }%`,
                            }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <span>
                          {(
                            (uploadState.processedProducts /
                              uploadState.totalProducts || 0) * 100
                          ).toFixed(2)}
                          % completed{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* // )} */}
              {!showMessage &&
              <div className="col-12">
                <div className="table-responsive pro_table">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Product Nft Url</th>
                        <th>Placeholder File Url</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Properties</th>
                        <th>Category</th>
                        <th>Sub Category</th>
                        {/* <th>Is Redeemable</th>
                        <th>Redemption Product</th> */}
                        <th>Product Type</th>
                        {/* <th>Redeemable By</th>
                        <th>Publish Status</th> */}
                        <th>Publish Date</th>
                        <th>Publish Time</th>
                        {!showUploadBar && <th>Actions </th>}
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length ? (
                        data?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className="col-blue">{item?.name}</td>
                            <td className="col-blue">
                              {item?.imageUrl}
                            </td>
                            <td className="col-blue">
                              {item?.imageUrl}
                            </td>
                            <td>
                              {!item.description
                                ? 'N.A'
                                : item.description.length > 20
                                ? item.description.slice(0, 20) + '...'
                                : item.description}
                            </td>
                            <td>
                              <span className={currencyUnit()}>{item.price || 0}</span>
                            </td>
                            <td>
                              {item?.properties[0]?.type || 'N.A'}
                              </td>
                            <td>{item.categories[0] || 'N.A'}</td>
                            <td>{item.subcategories || 'N.A'}</td>
                            {/* <td>{item.csvIsRedeemable ? 'Yes' : 'No'}</td>
                            <td>{item.csvRedemptionProduct || 'N.A'}</td> */}
                            <td>{convertStatus(item.csvProductType)}</td>
                            {/* <td>{item.csvRedeemableBy || 'N.A'}</td>
                            <td>{item.csvStatus ? 'Yes' : 'No'}</td> */}
                            <td>{formatDate(item?.updatedAt)}</td>
                            <td>{formatTime(item?.updatedAt)}</td>
                            {!showUploadBar && (
                              <td>
                                <button
                                  type="button"
                                  className="btn-red"
                                  // onClick={() => handleRemove(item.id)}
                                >
                                  <i className="fa fa-trash" /> 
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4}>No results to show</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* <div className="mt-3 d-flex justify-content-end">
                  {data.validRecordCount > 0 ? (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={PER_PAGE}
                      totalItemsCount={data.totalProducts}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                      innerClass="pagination mt-3 font-link"
                      itemClass="paginate_button page-item"
                      linkClass="page-link"
                      activeClass="active"
                      hideFirstLastPages={true}
                      prevPageText="Previous"
                      nextPageText="Next"
                    />
                  ) : null}
                </div> */}
                <div className="d-flex justify-content-between">
                  {data && data?.length ? (
                    <a
                      href="#"
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        confirmMessage()
                      }}
                      className="btn w-auto d-inline-block mx-0"
                    >
                      Continue
                    </a>
                  ) : null}
                  {!data || data?.length==0 ? (
                    <button
                      className="btn w-auto d-inline-block mx-0"
                    >
                      Download Error Logs
                    </button>
                  ) : null}
                </div>
              </div>
              }
              {showMessage &&
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <p className="fontSz">The import has started in background. Please go to Products page to check.</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <a
                      onClick={(e: React.MouseEvent) => {
                        history.push(URLS.PRODUCT)
                      }}
                      className="btn w-auto d-inline-block mx-0"
                    >
                      Go To Product
                    </a>
                </div>
                  </div>

              }

            </div>
          </div>
        </div>
      </div>
    </section>

      {/* </form> */}
    </>
  );
}

export default PreviewUpload;
