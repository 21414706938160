
import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../common/appApiCallHook'


export function useProductApi() {

    const callApi = useApiCall()

    const addProduct = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.ADD, data, onSuccess, onError);
    }
    const listProduct = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_LIST, data, onSuccess, onError);
    }
    const productDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_DETAILS, data, onSuccess, onError);
    }
    const editProduct = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.UPDATE, data, onSuccess, onError);
    }

    const addStatusChangeForCollection = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PAYOUT.PAYOUT_STATUS, data, onSuccess, onError);
    }

    const updateFileProduct = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.FILE, data, onSuccess, onError);
    }

    return {
        callAddProduct: addProduct,
        callListProduct: listProduct,
        callProductDetails: productDetails,
        callEditProduct: editProduct,
        callUpdateFile: updateFileProduct,

        callPayoutStatus: addStatusChangeForCollection,
    }
}