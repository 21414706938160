import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { URLS, PER_PAGE } from "src/_config";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import {
  AddGroupReq,
  collectionType,
  filterUrlValues,
  OptionValue,
  STATUS_TYPE,
} from "src/_common/interfaces/common";
// import { usePayoutApi } from "src/_common/hooks/actions/payout/payoutApiHook";
import { useProductApi } from "src/_common/hooks/actions/product/productApiHook";
import Pagination from "react-js-pagination";
import * as qs from "query-string";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import {
  useCollectionListSelector,
  useGenesisDetailsSelector,
} from "src/_common/hooks/selectors/eventSelector";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import { useCollectionApi } from "src/_common/hooks/actions/collection/collectionApiHook";

interface Props {
  slug: string;
  collection: any;
}

interface FormValues {
  name: string;
  category: any;
  status: string;
}

// PayoutListForm
const resetValue = {
  name: "",
  category: {},
  status: "",
};
const ProductListForm = ({ slug, collection }: Props) => {
  const { control, handleSubmit, errors, reset } = useForm<any>({
    // resolver: yupResolver(CollectionSchema),
    defaultValues: resetValue,
  });
  const collectionApi = useCollectionApi();

  // const payoutApi = usePayoutApi();
  const productApi = useProductApi();

  const toast = useToaster();
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [activePage, setactivePage] = useState<number>(1);
  const [filtersFieldShowHide, setfiltersFieldShowHide] =
    useState<boolean>(false);
  const useCollectionListsSelector: any = useCollectionListSelector();
  const [networkListValue, setNetworkListValue] = useState<any>([]);
  const [categoriesListValue, setCategoriesListValue] = useState<any>([]);

  const location = useLocation();

  // this one for filter data submit and set page 1 for pagination

  const onSubmit = (data: any) => {
    let params = {
      ...data,

      fields: "categories",
    };

    console.log("paras", params);

    params = filterUrlValues(params);

    // if (params.productName) {
    //   params['itemName'] = params.productName;
    // }
    if (params.status) {
      params["status"] = params.status.value;
    }
    if (params?.categories?.value) {
      params["categories"] = params.categories.value;
      params["fields"] ="categories";
      
    }
    // fields: "categories",

    params["page"] = 1;
    params["perPage"] = PER_PAGE;
    setactivePage(1);
    history.push(URLS.PRODUCT + `?${qs.stringify(params)}`);

    productApi.callListProduct(
      params,
      (message: string, resp: any) => {
        if (resp) {
          // console.log("resCOlle", resp);
          setData(resp);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  // const collections_id:any=localStorage.getItem('collection_id')
  // console.log("collections_id36",collections_id)
  const collections_name: any = localStorage.getItem("collection_name");

  // useEffect(() => {
  //   // getPayoutList()
  //   getProductList();
  // }, []);

  const goToAddProduct = (id: any) => {
    history.push({
      pathname: URLS.PRODUCT_ADD,
      state: { collection: collection, slug: slug, id: id },
    });
  };

  const goToEditProduct = (tokenId: string, contractAddress: string) => {
    if (tokenId && contractAddress) {
      history.push({
        pathname: `${URLS.PRODUCT_LIST}/${contractAddress}/${tokenId}`,
        state: { tokenId: tokenId, contractAddress: contractAddress },
      });
    }
    return;
  };

  // this one for reset  filter data
  const resetData = () => {
    reset(resetValue);
    history.push(URLS.PRODUCT);
    let pageNumberFirstTime = 1;
    setactivePage(1);
    setTimeout(() => {
      getProductList(pageNumberFirstTime, "reset");
    }, 500);
  };

  useEffect(() => {
    getCategoriesList();
  }, []);
  // if there have any params in url then it passes params through url

  useEffect(() => {
    const queryVal: any = queryString.parse(location.search);
    if (queryVal && queryVal?.page) {
      setactivePage(Number(queryVal?.page));
      reset(queryString.parse(location.search));
      // console.log(queryString.parse(location.search));
      getProductList(queryVal?.page, "first time");
    } else {
      getProductList(1, "first time");
    }
  }, []);

  // pagination page change and set

  const handlePageChange = (pageNumber: any) => {
    let temp = { ...queryString.parse(location.search) };
    temp["page"] = pageNumber;
    history.push(URLS.PRODUCT + `?${qs.stringify(temp)}`);
    // console.log("temp", temp);
    // console.log("pageNumber", pageNumber);
    setactivePage(pageNumber);
    getProductList(pageNumber, "page change");
  };
  // if filter button click then show filter fields

  const filterShowHide = (flts: any) => {
    setfiltersFieldShowHide(flts);
  };
  const getProductList = (pageNumber: any, type: any) => {
    const params: any = {
      // collections_id:collections_id
      fields: "categories",
      perPage: PER_PAGE,
    };

    if (type != "reset") {
      for (let [key, value] of Object.entries(
        queryString.parse(location.search)
      )) {
        params[key] = value;
      }
    }
    // params["fields"]="categories";

    params["page"] = pageNumber;

    console.log(params);

    // params['collections_id']=collections_id
    // console.log("collectioId",collections_id)

    productApi.callListProduct(
      params,
      (message: string, resp: any) => {
        if (resp) {
          // console.log("res", resp);
          setData(resp);
          // setCategory(resp.data[0].categories)
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const goToBulkUploadPage = () => {
    history.push({
      pathname: URLS.UPLOAD_PRODUCT,
    });
  };

  // <--------------this one for categories list--------------------->

  const getCategoriesList = () => {
    const params: any = {};

    collectionApi.callGetCategories(
      params,
      (message: string, resp: any) => {
        console.log("respcat", resp);
        setCategoriesListValue(resp.data);
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  // console.log("data", data, "cate", category);

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="information single_pro_btn common-white-box">
                <div className="c_detail ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>Products</h2>
                    <div className="d-flex align-items-center">
                      <a
                        className="btn m-2"
                        href-="#"
                        //   onClick={(e) => {
                        //     e.preventDefault();
                        //     setShowModal(true);
                        //   }}
                        onClick={() => goToAddProduct("")}
                      >
                        {/* <img
                        src={`assets/images/upload-btn.png`}
                        alt="#"
                      /> */}
                        {/* {' '} */}
                        Add Product
                      </a>

                      <a
                        className="btn btnMoves"
                        href-="#"
                        onClick={() => goToBulkUploadPage()}
                      >
                        Bulk Upload
                      </a>
                      <div
                        className="cursor-pointer d-flex justify-content-between align-items-baseline ms-3 flter-blue-btn"
                        onClick={() => filterShowHide(!filtersFieldShowHide)}
                      >
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>

                {filtersFieldShowHide && (
                  <div className="row mb-3">
                    <div className="d-flex flex-row-reverse">
                    <div
                        className="cursor-pointer d-flex justify-content-between align-items-baseline ms-3 flter-blue-btn mb-3"
                        onClick={() => filterShowHide(!filtersFieldShowHide)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div>
                      <div className="card">
                        <div className="">
                          <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="row mb-3">
                                <div className="col-lg-5 col-md-3 col-12 mt-mobile-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Product Name{" "}
                                    </label>
                                    <Controller
                                      defaultValue=""
                                      control={control}
                                      name="name"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          error={errors.name}
                                          placeholder="Enter Product Name"
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-12 mt-mobile-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Category{" "}
                                    </label>

                                    <Controller
                                      defaultValue={""}
                                      control={control}
                                      name="categories"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <SelectInput className="box"
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          options={
                                            categoriesListValue.length
                                              ? categoriesListValue.map(
                                                  (opt: any) => ({
                                                    label: opt.name,
                                                    value: opt._id,
                                                  })
                                                )
                                              : []
                                          }
                                          error={errors.categories}
                                          placeholder={`Select Category`}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-12 mt-mobile-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Status{" "}
                                    </label>
                                    <Controller 
                                      defaultValue={""}
                                      control={control}
                                      name="status"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <SelectInput className="box"
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          options={STATUS_TYPE.map(
                                            (opt: any) => ({
                                              label: opt.label,
                                              value: opt.value,
                                            })
                                          )}
                                          error={errors.status}
                                          placeholder={`Select Status`}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-lg-3 col-md-3 col-12 mt-mobile-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Collection{' '}
                                </label>
                                <Controller
                                  control={control}
                                  name="endDate"
                                  render={({ onChange, onBlur, value, name, ref }) => (
                                    <AsyncSelectInput
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      options={collectionPromise}
                                      inputRef={ref}
                                      name="collection"
                                    />
                                  )}
                                />
                              </div>
                            </div> */}
                              </div>
                              <div className="d-flex justify-content-end mb-4 mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary btnclours mx-2 "
                                >
                                  Filter
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger btnclours "
                                  onClick={resetData}
                                >
                                  Clear
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="table-responsive pro_table tableFixHead">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>
                          {/* <i
                          className="fa fa-refresh cursor-pointer"
                        /> */}
                        </th>
                        <th>Product Name</th>
                        <th>Collection Name</th>
                        <th>Collection Type</th>
                        <th>Category</th>
                        <th>Payout</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {data && data?.total > 0 ? (
                      data?.data?.map((item: any, index: number) => {
                        return (
                          <tbody key={item._id}>
                            <tr>
                              <td></td>
                              <td>{item?.itemName}</td>

                              <td>
                                {collections_name ? collections_name : "N.A"}
                              </td>
                              <td>
                                {collectionType(
                                  item?.collection?.contractCollectionType
                                )}
                              </td>
                              <td>
                                {item?.categories.map((val: any) => {
                                  return (
                                    <ul
                                      key={val?._id}
                                      className="listing_categories"
                                    >
                                      <li>{val?.abbreviation}</li>
                                    </ul>
                                  );
                                })}
                              </td>

                              <td>
                                {/* {collectionType(item?.collection?.contractCollectionType)} */}
                                {/* {collections_name? collections_name : 'N.A'} */}
                                {"N.A"}
                              </td>
                              <td>
                                {/* {collectionType(item?.collection?.contractCollectionType)} */}
                                {/* {collections_name? collections_name : 'N.A'} */}
                                {item?.status}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn-blue "
                                  title="Edit"
                                  onClick={() => {
                                    // setRowId(item.id);
                                    // setShowModal(true);
                                    // goToAddProduct(item?._id)
                                    goToEditProduct(
                                      item?.tokenId,
                                      item?.contractAddress
                                    );
                                  }}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={9} align="center">
                            No results found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {data && data?.total > 0 ? (
                <div className="d-flex justify-content-end mt-3">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={PER_PAGE}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    innerClass="pagination mt-3 font-link"
                    itemClass="paginate_button page-item"
                    linkClass="page-link"
                    activeClass="active"
                    hideFirstLastPages={true}
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* {showModal && (
        <PayoutGroupModal show={true} close={closeModal} id={rowId} />
      )} */}
      </section>

      {/* </form> */}
    </>
  );
};

export default ProductListForm;
