import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { EventGameDetails } from 'src/_common/interfaces/models/event';
import { StateExtended } from '../../interfaces/StateExtended'



export function useCollectionListSelector() {
    const getCollectionList = useSelector((state: StateExtended) => state.event.getCollectionList)
    return getCollectionList;
}

export function useGenesisDetailsSelector() {
    const getGenesisDetails = useSelector((state: StateExtended) => state.event.getGenesisDetails)
    return getGenesisDetails;
}