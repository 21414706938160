import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Sidebar from 'src/components/common/Sidebar'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from "../../_config";
import PreviewUpload from "../../components/previewUpload/previewUpload";
import Header from "src/components/common/Header";


export default function PreviewUploads(props: any) {
 
  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${APPLICATION_NAME} : Genesis`;
    return () => {
      document.title = prevTitle;
    };
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className="container">
          <Header type="side" />
        </div>
        <PreviewUpload />
      </section>
    </React.Fragment>
  );
}
