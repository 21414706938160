import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, useLocation, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import AddPropertyModal from "./modals/addPropertyModal";
import { LevelType, PropertyType } from "src/_common/interfaces/ProductManage";

import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useCollectionApi } from "src/_common/hooks/actions/collection/collectionApiHook";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import Modal from "react-bootstrap/Modal";
import {
  AddProductReq,
  BLOCKCHAIN_TYPE,
  CONTRACT_TYPE,
  OptionValue,
} from "src/_common/interfaces/common";
import * as yup from "yup";

import FormTexAreatInput from "src/_common/components/form-elements/texareaInput";
import { useCollectionListSelector } from "src/_common/hooks/selectors/eventSelector";
import SwitchInput from "src/_common/components/form-elements/switchInput";
import { Button } from "react-bootstrap";
import { useProductApi } from "src/_common/hooks/actions/product/productApiHook";
import ConfirmAlert from "src/_common/components/form-elements/confirmAlert";
import { URLS } from "src/_config";
import { register } from "src/serviceWorker";
import AddLevelModal from "./modals/addLevelModal";

interface ProductFormValues {
  itemName: string;
  url: string;
  description: string;
  contractCollectionType: OptionValue;
  blockchain: OptionValue;
  network: OptionValue;
  categories: OptionValue;
  explicit: boolean;
  unlockable: boolean;
  unlockableText: string;
  supply: number;
}

interface Props {
  slug: string;
  collection: any;
}

export const productGroupSchema = yup.object().shape({
  itemName: yup.string().required("Item Name is required"),
  // logoImage: yup.string().required("File is required"),
  properties: yup.array().of(
    yup.object().shape({
      type: yup.string(),
      name: yup.string(),
    })
  ),
  description: yup.string(),
  contractCollectionType: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Token Standard is required"),
  network: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Network is required"),
  categories: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Category is required"),
});

const ProductForm = ({ slug, collection }: Props) => {
  let { tokenId, contractAddress } = useParams<any>();
  const history = useHistory();
  const location: any = useLocation();
  const collections_id: any = localStorage.getItem("collection_id");
  const { control, handleSubmit, errors, reset, setValue, watch } =
    useForm<any>({
      resolver: yupResolver(productGroupSchema),
    });
  const collectionApi = useCollectionApi();
  const productApi = useProductApi();
  const toast = useToaster();
  const [collectionLogo, setSelectedLogo] = useState<any>(null);
  const [productImage, setProductImage] = useState<any>(null);
  const [dataCollectionLogo, setDataLogo] = useState<any>(null);
  const [data, setUserData] = useState<any>(null);
  const [fileLogo, setfileLogo] = useState<any>(null);
  const [comment, setComment] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [categoriesListValue, setCategoriesListValue] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [networkListValue, setNetworkListValue] = useState<any>([]);
  const [logoId, setLogoId] = useState<any>(null);

  const useCollectionListsSelector: any = useCollectionListSelector();
  const [selectedProperties, setSelectedProperties] = useState<PropertyType[]>(
    []
  );
  const [showLevelModal, setShowLevelModal] = useState<boolean>(false);
  const [selectedLevel, setSelectedLevel] = useState<LevelType[]>([]);

  const formValues: any = watch();
  // console.log("formValues", formValues);

  const onSelectFile = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let chooseImage = URL.createObjectURL(e.target.files[0]);
      setSelectedLogo(chooseImage);
      setDataLogo({ file, fileName: file.name, mimeType: file.type });
      let formData: any = new FormData();
      formData.append("file", file);

      productApi.callUpdateFile(
        formData,
        (message: string, resp: any) => {
          if (resp) {
            console.log("select logo id", resp);
            console.log("e", e);

            // setLogoId(resp._id);
            // setValue("logoImage", resp._id);
            setLogoId(resp._id);
          } else {
            toast.error(message);
          }
        },
        (message: string) => {
          toast.error(message);
        }
      );
    }
  };

  useEffect(() => {
    getCategoriesList();
    getNetworkList();
  }, []);

  useEffect(() => {
    if (tokenId && contractAddress) {
      setEditMode(true);
    }
  }, []);

  const getCategoriesList = () => {
    const params: any = {};

    collectionApi.callGetCategories(
      params,
      (message: string, resp: any) => {
        setCategoriesListValue(resp.data);
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const getNetworkList = () => {
    const params: any = {};

    collectionApi.callGetNetwork(
      params,
      (message: string, resp: any) => {
        setNetworkListValue(resp?.data);
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  useEffect(() => {
    if (editMode) {
      getProductDetails();
    }
  }, [editMode]);

  const getProductDetails = () => {
    let loc: any = location;

    const params: any = {
      contractAddress: contractAddress,
      tokenId: tokenId,
    };

    productApi.callProductDetails(
      params,
      (message: string, resp: any) => {
        console.log("resdetaproductForm", resp);
        setfileLogo(resp?.logoImage?.fileUrl);
        let categoriesType: any = {
          label: resp?.categories[0]?.name,
          value: resp?.categories[0]?._id,
        };
        let collectsType: any = {
          label: resp?.collectionEntity?.contractCollectionType,
          value: resp?.collectionEntity?.contractCollectionType,
        };
        let networkss: any = {
          label: resp?.networkChainLabel,
          value: resp?.network,
        };
        setSelectedProperties(resp?.properties?.length ? resp?.properties : []);
        console.log("resetres", resp);
        console.log("logo", resp?.logoImage?._id);
        setSelectedLevel(resp?.levels?.length ? resp?.levels : []);
        setLogoId(resp?.logoImage?._id || null);

        setComment(resp?.unlockable);

        reset({
          itemName: resp && resp.itemName ? resp.itemName : "",
          logoImage: resp?.logoImage?._id,
          description: resp?.description,
          unlockable: resp?.unlockable,
          unlockableText: resp?.unlockableText,
          supply: resp?.supply,

          explicit: resp?.explicit,
          categories: categoriesType,
          contractCollectionType: collectsType,
          network: networkss,
        });
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  // <--------------this one for add or edit collection submit by condition--------------------->

  // const onSubmit = (values: ProductFormValues) => {
  //   console.log("value", values);
  //   let params: any = { ...values };
  //   params["network"] = values.network.value;
  //   params["categories"] = [values.categories.value];
  //   params["contractCollectionType"] = values.contractCollectionType.value;
  //   // params['logoImage']=logoId? logoId : useCollectionListsSelector?.collection?.logoImage?._id
  //   params["contractAddress"] = "0xb79C4a80F6aBAd70B0b2A3ecAc5Db2EdeE9C8Af2";

  //   productAdd(params);
  // };
  const handleSubmitForm = (values: AddProductReq) => {
    let loc: any = location;
    console.log("value", values);
    // console.log("propertyFields", propertyFields);

    // let params: any = {
    //   ...values,
    //   properties: propertyFields.map((item) => ({
    //     key: item.type,
    //     value: item.name,
    //   })),
    // };
    let params: any = {
      ...values,
    };

    params["network"] = values.network.value;
    params["categories"] = [values.categories.value];
    params["contractCollectionType"] = values.contractCollectionType.value;
    params["contractAddress"] = "0xb79C4a80F6aBAd70B0b2A3ecAc5Db2EdeE9C8Af2";
    params["collectionEntity"] = collections_id ? collections_id : null;

    params["explicit"] = !!values.explicit;
    params["unlockable"] = !!values.unlockable;
    params["unlockableText"] = values?.unlockableText;
    params["supply"]=values?.supply;
    // params["properties"] =
    //   selectedProperties && selectedProperties.length
    //     ? [...selectedProperties]
    //     : null;
    params["properties"] =
      selectedProperties && selectedProperties.length
        ? [...selectedProperties]
        : null;

    params["levels"] =
      selectedLevel && selectedLevel.length ? [...selectedLevel] : null;

    // console.log("paramsadd", params);

    params["logoImage"] = logoId;
    console.log("paramsadd", params);
    // return;

    if (editMode) {
      params["tokenId"] = tokenId;

      console.log("edit");
      productEdit(params);
    } else {
      console.log("add");

      productAdd(params);
    }
  };
  const productEdit = (parms: any) => {
    productApi.callEditProduct(
      parms,
      (message: string, resp: any) => {
        if (resp) {
          toast.success(message);

          history.push({
            pathname: URLS.PRODUCT_LIST,
          });
        } else {
          toast.error(message);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const productAdd = (parms: any) => {
    productApi.callAddProduct(
      parms,
      (message: string, resp: any) => {
        if (resp) {
          toast.success(message);
          history.push({
            pathname: URLS.PRODUCT_LIST,
          });
        } else {
          toast.error(message);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const setPropertiesValueHandler = (selectedProp: PropertyType[]) => {
    setSelectedProperties(selectedProp);
  };
  const setLevelValueHandler = (selectedLev: LevelType[]) => {
    setSelectedLevel(selectedLev);
  };

  return (
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="information get_start tab-sec-n settings-custom-wrapper">
                <div className="row">
                  <div className="col-12 px-3">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      {editMode ? <h2>Edit Product</h2> : <h2>Add Product</h2>}
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <div className="login-text ">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Item Name <span className="text-danger">*</span>
                              </label>
                              <Controller
                                defaultValue={""}
                                control={control}
                                name="itemName"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    error={errors.itemName}
                                    placeholder="Enter Item Name"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-12">
                          <div className="login-text ">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Image, Video, Audio or 3D Model
                                <span className="text-danger">*</span>{" "}
                              </label>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  accept=".jpg, .jpeg, .png"
                                  onChange={(e) => onSelectFile(e)}
                                />

                                <div>
                                  {!collectionLogo ? (
                                    <img
                                      src={
                                        fileLogo
                                          ? fileLogo
                                          : "../images/no_image.png"
                                      }
                                      className="imageHeightShowing leftPositionMove mt-2"
                                      alt="Logo"
                                    />
                                  ) : collectionLogo ? (
                                    <img
                                      src={collectionLogo}
                                      className="imageHeightShowing leftPositionMove mt-2"
                                      alt="Logo"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Description{" "}
                            </label>
                            <Controller
                              defaultValue=""
                              control={control}
                              name="description"
                              render={({
                                onChange,
                                onBlur,
                                value,
                                name,
                                ref,
                              }) => (
                                <FormTexAreatInput
                                  name={name}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  inputRef={ref}
                                  type="text"
                                  rows={5}
                                  error={errors.description}
                                  placeholder=""
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-12">
                          <div className="login-text ">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Token Standard{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                defaultValue={
                                  useCollectionListsSelector?.collection
                                    ?.contractCollectionType
                                    ? useCollectionListsSelector?.collection
                                        ?.contractCollectionType
                                    : ""
                                }
                                control={control}
                                name="contractCollectionType"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <SelectInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    options={CONTRACT_TYPE.map((opt: any) => ({
                                      label: opt.label,
                                      value: opt.value,
                                    }))}
                                    error={errors.contractCollectionType}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="login-text ">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Categories{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                // defaultValue={
                                //   useCollectionListsSelector?.collection &&
                                //   useCollectionListsSelector?.collection
                                //     ?.categories
                                //     ? useCollectionListsSelector?.collection
                                //         ?.categories
                                //     : ""
                                // }
                                defaultValue={""}
                                control={control}
                                name="categories"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <SelectInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    options={
                                      categoriesListValue.length
                                        ? categoriesListValue.map(
                                            (opt: any) => ({
                                              label: opt.name,
                                              value: opt._id,
                                            })
                                          )
                                        : []
                                    }
                                    error={errors.categories}
                                    placeholder={`Category`}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <Controller
                              defaultValue=""
                              control={control}
                              name="unlockable"
                              render={({
                                onChange,

                                onBlur,
                                value,
                                name,
                                ref,
                              }) => (
                                <SwitchInput
                                  onChange={(e) => {
                                    console.log("function call");
                                    setComment(!comment);

                                    onChange(e);
                                  }}
                                  name={name}
                                  value={value}
                                  inputRef={ref}
                                  id={name}
                                  label={
                                    "Unlockable Content ( Content that can only be seen by the owner )"
                                  }
                                />
                              )}
                            />

                            {comment === true ? (
                              <Controller
                                defaultValue=""
                                control={control}
                                name="unlockableText"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormTexAreatInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    rows={5}
                                    error={errors.unlockableText}
                                    placeholder="Enter content (access key, code to redeem,link to a file,etc)"
                                  />
                                )}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <Controller
                              defaultValue=""
                              control={control}
                              name="explicit"
                              render={({
                                onChange,
                                onBlur,
                                value,
                                name,
                                ref,
                              }) => (
                                <SwitchInput
                                  onChange={onChange}
                                  name={name}
                                  value={value}
                                  inputRef={ref}
                                  id={name}
                                  label={
                                    "Explicit & Sensitive Content ( Set as explicit and sensitive content )"
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                        {/* <h3 className="mt-3">Royalties</h3> */}

                        {/* <div className="col-sm-6 col-12">
                          <div className="login-text ">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                External Link{" "}
                                <span className="text-danger">*</span>
                                (Shopify will include a link you can share and
                                promote your NFT)
                              </label>
                              <Controller
                                defaultValue={""}
                                control={control}
                                name="shortName"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    // readOnly={data?.slug}
                                    error={errors.url}
                                    placeholder="External Link"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <div className="login-text ">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Blockchain{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  defaultValue={
                                    useCollectionListsSelector?.collection &&
                                    useCollectionListsSelector?.collection
                                      ?.network
                                      ? useCollectionListsSelector?.collection
                                          ?.network
                                      : ""
                                  }
                                  control={control}
                                  name="network"
                                  render={({
                                    onChange,
                                    onBlur,
                                    value,
                                    name,
                                    ref,
                                  }) => (
                                    <SelectInput
                                      name={name}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      inputRef={ref}
                                      // options={
                                      //   Object.values(networks).length
                                      //     ? Object.values(networks).map(
                                      //         (opt: any) => ({
                                      //           label: opt.chainLabel,
                                      //           value: opt.id,
                                      //         }),
                                      //       )
                                      //     : []
                                      // }
                                      options={
                                        networkListValue.length
                                          ? networkListValue.map(
                                              (opt: any) => ({
                                                label: opt.chainLabel,
                                                value: opt._id,
                                              })
                                            )
                                          : []
                                      }
                                      isDisabled={
                                        useCollectionListsSelector?.collection &&
                                        useCollectionListsSelector?.collection
                                          ?.collection?.slug
                                      }
                                      error={errors.network}
                                      placeholder={`Network`}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-12">
                            <div className="login-text ">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Supply <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  defaultValue={""}
                                  control={control}
                                  name="supply"
                                  render={({
                                    onChange,
                                    onBlur,
                                    value,
                                    name,
                                    ref,
                                  }) => (
                                    <FormTextInput
                                      name={name}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      inputRef={ref}
                                      type="number"
                                      error={errors.supply}
                                      placeholder="0"
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-12">
                          <div className="login-text ">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Properties{" "}
                              </label>

                              <Button
                                variant="primary"
                                onClick={() => setShow(true)}
                              >
                                Add Properties
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-12">
                          <div className="login-text ">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                {/* Levels <span className="text-danger">*</span> */}
                              </label>
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={() => setShowLevelModal(true)}
                              >
                                Add Levels
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12" />
                      </div>
                      <div className="row mt-3">
                        <div className="row">
                          <div className="col-lg-6">
                            <button
                              type="button"
                              className="btn btn-success ms-2"
                              onClick={handleSubmit((d: any) => {
                                handleSubmitForm(d);
                              })}
                            >
                              {editMode ? "Update" : "Submit"}
                            </button>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-8">
                            {data?.status === "pending" && (
                              <div className="alert alert-info" role="alert">
                                Your contract is being deployed.{" "}
                                <a href={data?.transactionLink} target="_blank">
                                  Click here
                                </a>{" "}
                                to see details
                              </div>
                            )}
                            {data?.status === "deployed" && (
                              <div className="alert alert-success" role="alert">
                                Your contract on the{" "}
                                <strong>{data?.network?.label}</strong>, is
                                successfully deployed.{" "}
                                <a href={data?.transactionLink} target="_blank">
                                  Click here
                                </a>{" "}
                                to see details. Contract Address:{" "}
                                <a
                                  href={data?.contractLink}
                                  target="_blank"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {data?.address}
                                </a>
                              </div>
                            )}
                            {data?.status === "failed" && (
                              <div className="alert alert-danger" role="alert">
                                Your contract could not be deployed. Please{" "}
                                <a href={data?.transactionLink} target="_blank">
                                  Click here
                                </a>{" "}
                                to check details
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddPropertyModal
        show={show}
        onClose={() => setShow(false)}
        setPropertiesValueHandler={setPropertiesValueHandler}
        defaultProperties={selectedProperties?.length ? selectedProperties : []}
      />
      <AddLevelModal
        show={showLevelModal}
        onClose={() => setShowLevelModal(false)}
        setLevelValueHandler={setLevelValueHandler}
        defaultLevel={selectedLevel?.length ? selectedLevel : []}
      />
    </>
  );
};

export default ProductForm;
