import React, { useState, useRef, forwardRef, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  AddLevelFormProps,
  LevelType,
} from "../../_common/interfaces/ProductManage";
import { Button } from "react-bootstrap";

const defaultVal: LevelType = { name: "", value_one: "", value_two: "" };

const AddLevelSchema = yup.object().shape({
  levels: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      value_one: yup.string(),
      value_two: yup.string(),
    })
  ),
});

const AddLevelForm = forwardRef((props: AddLevelFormProps, ref) => {
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(AddLevelSchema),
    mode: "onBlur",
  });
  const { defaultLevel, setLevelValueHandler, successHandler } = props;
  const [levels, setLevels] = useState<LevelType[]>(
    defaultLevel?.length ? [...defaultLevel] : [defaultVal]
  );
  let { levels: formLevels }: any = watch();

  useEffect(() => {
    reset({ levels: levels });
  }, [levels]);

  const removeLevelHandler = (selectedIndex: number) => {
    let tempFormLevels =
      formLevels && formLevels.length ? [...formLevels] : [defaultVal];

    if (tempFormLevels[selectedIndex]) {
      tempFormLevels.splice(selectedIndex, 1);
    }

    setLevels(tempFormLevels);
  };

  const addMoreHandler = () => {
    let tempFormLevels =
      formLevels && formLevels.length ? [...formLevels] : [defaultVal];
    tempFormLevels.push(defaultVal);
    setLevels(tempFormLevels);
  };

  const onSubmit = (values: any) => {
    let { levels: submittedLevels } = values;
    submittedLevels = submittedLevels.filter(
      (item: LevelType) =>
        typeof item.name !== "undefined" &&
        typeof item.value_one !== "undefined" &&
        typeof item.value_two !== "undefined"
    );

    setLevelValueHandler && setLevelValueHandler(submittedLevels);
    successHandler && successHandler();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end mt-4">
          <button
            type="button"
            className="btn btn-primary ms-3"
            onClick={() => addMoreHandler()}
            title="Add more"
          >
            <i className="fa fa-plus" />
          </button>
        </div>

        <div
          className="table-responsive pro_table tableFixHead mt-4"
          style={{
            maxHeight: "300px",
            overflow: "auto",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {levels.map((val: LevelType, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <Controller
                        defaultValue={val.name ? val.name : ""}
                        control={control}
                        name={`levels.${index}.name`}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <FormTextInput
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            type="text"
                            placeholder="Enter name"
                            error={errors?.levels?.[index]?.name}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <div className="input-group mb-3">
                        <Controller
                          defaultValue={val.value_one ? val.value_one : ""}
                          control={control}
                          name={`levels.${index}.value_one`}
                          render={({ onChange, onBlur, value, name, ref }) => (
                            <FormTextInput
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              inputRef={ref}
                              type="number"
                              placeholder="Enter value"
                              error={errors?.levels?.[index]?.value_one}
                              min={0}
                            />
                          )}
                        />

                        <span className="input-group-text">OF</span>

                        <Controller
                          defaultValue={val.value_two ? val.value_two : ""}
                          control={control}
                          name={`levels.${index}.value_two`}
                          render={({ onChange, onBlur, value, name, ref }) => (
                            <FormTextInput
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              inputRef={ref}
                              type="number"
                              placeholder="Enter value"
                              error={errors?.levels?.[index]?.value_two}
                              min={0}
                            />
                          )}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="btn-group btn-block">
                        {levels.length > 1 ? (
                          <button
                            type="button"
                            className="btn-red "
                            title="Delete"
                            onClick={() => removeLevelHandler(index)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        ) : null}

                        <button
                          type="button"
                          className="btn-green "
                          title="Add More"
                          onClick={() => addMoreHandler()}
                        >
                          <i className="fa fa-plus" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </div>
        </div>
      </form>
    </>
  );
});

export default AddLevelForm;
