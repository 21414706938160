import { confirmAlert } from 'react-confirm-alert';
interface ConfirmAlertProps {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  closeOnEscape?: boolean,
  closeOnClickOutside?: boolean,
}

const ConfirmAlert = ({
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  closeOnEscape,
  closeOnClickOutside,
}: ConfirmAlertProps) => confirmAlert({
  title: title || 'Are you sure?',
  message: message || 'Do you wish to continue?',
  buttons: [
    {
      label: confirmButtonText || 'Yes',
      onClick: () => onConfirm && onConfirm()
    },
    {
      label: cancelButtonText || 'Cancel',
      onClick: () => onCancel && onCancel()
    }
  ],
  closeOnEscape: !!closeOnEscape,
  closeOnClickOutside: !!closeOnClickOutside,
})

export default ConfirmAlert