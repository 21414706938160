import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';



export function* listOrder(action: any): any {
    try {
        const collections_id: any = localStorage.getItem('collection_id')

        // ${data?.params?.collections_id}
        const data = action.payload;
        // console.log("data", data)
        const resp = yield call(CallApi.GET, `${API_URL.ORDER.ORDER_LIST}/`, data, true);
        // 63203ca1407abbedf3b74b53 6321d3175b356d367386cf4d
        // const resp = yield call(CallApi.GET, `${API_URL.PRODUCT.PRODUCT_LIST}/6322df31563d0769f2c1e537`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* detailsProduct(action: any): any {
    const getSlug = localStorage.getItem('slug')
    const slug = 'avijit'
    try {
        const data = action.payload;
        console.log("data", data)
        const resp = yield call(CallApi.GET, `${API_URL.PRODUCT.PRODUCT_LIST}/${data?.contractAddress}/${data?.tokenId}?fields=categories,collectionEntity,logoImage`);
        if (resp && resp.status == 200) {
            yield put({
                type: ACTIONS.PRODUCT.PRODUCT_DETAILS,
                payload: resp.data.data
            })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


// export function* editProduct(action: any): any {
//     try {
//         const data = action.payload;
//         // console.log("dataeditpayout", data)
//         const resp = yield call(CallApi.PUT, `${API_URL.PRODUCT.UPDATE}/${data?.contractAddress}/${data?.tokenId}`, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             // yield put({
//             //     type: ACTIONS.EVENT.LEADERBOARD,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e: any) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }


// export function* payoutStatusChangeForCollection(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.PUT, `${API_URL.PAYOUT.PAYOUT_STATUS}/${data?.collection_slug}`, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             // yield put({
//             //     type: ACTIONS.EVENT.LEADERBOARD,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e: any) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }


export function* orderDetails(action: any): any {
    try {

        const data = action.payload;
        let ordersId= data?.orderId
        delete data['orderId']
        // console.log("data", data)
        const resp = yield call(CallApi.GET, `${API_URL.ORDER.ORDER_LIST}/${ordersId}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
