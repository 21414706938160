import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { AddPropertyModalProps } from "../../../_common/interfaces/ProductManage";
import AddPropertyForm from "../addPropertyForm";

const AddPropertyModal = (props: AddPropertyModalProps) => {
  const { show, onClose, defaultProperties, setPropertiesValueHandler } = props;

  const successHandler = () => {
    onClose && onClose();
  };
  return (
    <>
      <Modal size="lg" show={show} onHide={onClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>{" "}
          <div className="modal-body-description px-4 my-4">
            <h4>ADD PROPERTIES</h4>
            <p className="mb-4 mt-4">
              Properties show up underneath your item, are clickable, and can be
              filtered in your collection's sidebar.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <AddPropertyForm
            defaultProperties={defaultProperties}
            setPropertiesValueHandler={setPropertiesValueHandler}
            successHandler={successHandler}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPropertyModal;
