// @ts-nocheck
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { STORAGE, URLS } from './_config';
import requireNoAuth from './_common/hoc/reqNoAuth';
import Login from './containers/login';
import LoginUser from './containers/collectionUser';
import Page404 from './containers/page-404';
import CacheBuster from './CacheBuster';
import CollectionUser from './containers/collectionUser/collection';
import GenesisUser from './containers/genesis/genesis';
import PayoutUser from './containers/payout/payout';
import PayoutTab from './containers/payoutTab/payoutTab';
import PayoutList from './containers/payoutList/payoutList';
import ProductUser from './containers/product/product';
import UploadProducts from './containers/uploadProduct/uploadProduct';
import PreviewUploads from './containers/previewUpload/previewUpload';
import { useCollectionApi } from './_common/hooks/actions/collection/collectionApiHook';
import { useCommonApi } from './_common/hooks/actions/commonApiCall/appCommonApiCallHook';

import ProductTab from './containers/productTab/productTab';
import ProductList from './containers/productList/productList';
import SettingsUser from './containers/settings/settings';
import ProductForm from './components/product/productForm';
import ProductTabForm from './components/product/productTabForm';
import ProductTabContainer from './containers/productTab/productTab';
import OrderList from './containers/orderList/orderList';
import OrderDetails from './containers/orderDetails';
import Customer from './containers/customer';
import CustomerDetails from './containers/customerDetails';




function App() {
  const commonApi = useCommonApi();

  useEffect(() => {
    let token:any = localStorage.getItem(STORAGE);
    console.log('tokenssss', token)
    if(!token || token=='null' || token==null){
    getToken()
    }
  }, [])

  const getToken = () => {
    const params:any = { 
    };

    commonApi.callGetToken(
      params,
      (message: string, resp: any) => {
        console.log(resp)
        // console.log('resp', resp.data[0])
        // setColloectionListValue(resp.data[0])
      },
      (message: string) => {
        // toast.error(message);
      }
    );
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <Router>
              <Switch>
                <Route exact path={URLS.HOME} component={(CollectionUser)} />
                <Route exact path={URLS.COLLECTION} component={(CollectionUser)} />
                <Route exact path={URLS.GENESIS} component={(GenesisUser)} />
                <Route exact path={URLS.PAYOUT} component={(PayoutUser)} />
                <Route exact path={URLS.PRODUCT} component={(ProductList)} />

                <Route exact path={URLS.PAYOUT_ADD_EDIT} component={(PayoutTab)} />
                <Route exact path={URLS.PRODUCT_ADD} component={(ProductTab)} />
                <Route exact path={URLS.PRODUCT_EDIT} component={(ProductTab)} />



                <Route exact path={URLS.PAYOUT_LIST} component={(PayoutList)} />
                <Route exact path={URLS.PRODUCT_LIST} component={(ProductList)} />
                {/* <Route exact path={URLS.PRODUCT} component={(ProductUser)} /> */}
                <Route exact path={URLS.UPLOAD_PRODUCT} component={(UploadProducts)} />
                <Route exact path={URLS.PREVIEW_UPLOAD} component={(PreviewUploads)} />
                <Route exact path={URLS.SETTINGS} component={(SettingsUser)} />

                <Route exact path={URLS.ORDER_LIST} component={(OrderList)} />
                <Route exact path={URLS.ORDER_DETAILS} component={(OrderDetails)} />
                <Route exact path={URLS.CUSTOMER} component={(Customer)} />
                <Route exact path={URLS.CUSTOMER_DETAILS} component={(CustomerDetails)} />






              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
