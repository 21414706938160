import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { URLS } from "src/_config";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import AsyncSelect from "react-select/async";
import { styles } from "src/_common/components/form-elements/styles";
import { AddGroupReq, OptionValue, PayOutTab } from "src/_common/interfaces/common";
import ConfirmAlert from "src/_common/components/form-elements/confirmAlert";
import { usePayoutApi } from "src/_common/hooks/actions/payout/payoutApiHook";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


interface PayoutFormValues {
    payout:OptionValue
}

const PayoutSchema = yup.object().shape({
    // payout: yup
    //   .string()
    //   .required('Payout is required')
  });


interface Props {
  slug: string;
  collection: any;
}

const PayoutTabForm = ({ slug, collection }: Props) => {
  const { control, handleSubmit, errors } = useForm<PayoutFormValues>({
    resolver: yupResolver(PayoutSchema),
    defaultValues: {
    //   payees: [blank_group],
    },
  });
  const [payoutData, setPayoutData] = useState<any>(null);

  const payoutApi = usePayoutApi();
  const toast = useToaster();
  const history = useHistory();


  useEffect(() => {
    console.log('collection', collection)
    getPayoutList();
  }, []);

  const getPayoutList = () => {
    const params: any = {};

    payoutApi.callListPayout(
      params,
      (message: string, resp: any) => {
        if (resp) {
            console.log(resp.data)
          setPayoutData(resp.data);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };


  const submitForms = (data: any) => {
    console.log(data?.payout?.value)
    let parms: any = { ...data };
    parms['collection_slug']=slug ? slug : ''
    parms['defaultPayoutGroup']=data?.payout?.value

    payoutApi.callPayoutStatus(
        parms,
        (message: string, resp: any) => {
          if (resp) {
            toast.success(message);
            
          } else {
            toast.error(message);
          }
        },
        (message: string) => {
          toast.error(message);
        }
      );


    // params["collectionEntity"] = collection?._id;
    // payoutApi.callAddPayout(
    //   params,
    //   (message: string, resp: any) => {
    //     if (resp) {
    //       toast.success(message);
    //     } else {
    //       toast.error(message);
    //     }
    //   },
    //   (message: string) => {
    //     toast.error(message);
    //   }
    // );
  };

  const goToAddPayout = () => {
    history.push({
      pathname: URLS.PAYOUT_ADD_EDIT,
      state: { collection: collection, slug: slug },
    });
  };

  return (
    // <form>
    <>
      <div className="mt-4">
        <div className="payment_card px-0">
          <div className="login-text">
            <form onSubmit={handleSubmit(submitForms)}>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <div className="form-group">
                    <h6>Payout Group</h6>
                    <Controller
                      defaultValue={""}
                      control={control}
                      name="payout"
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <SelectInput
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          inputRef={ref}
                          options={
                            payoutData && payoutData.length
                              ? payoutData.map((opt: any) => ({
                                  label: opt.name,
                                  value: opt._id,
                                }))
                              : []
                          }
                          error={errors.payout}
                          placeholder={`Payout`}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-3">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => goToAddPayout()}
                  >
                    Add New
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <button type="submit" className="btn btn-primary ml-0">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* </form> */}
    </>
  );
};

export default PayoutTabForm;
