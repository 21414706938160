import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useCollectionApi } from "src/_common/hooks/actions/collection/collectionApiHook";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import FormTexAreatInput from "src/_common/components/form-elements/texareaInput";
import TinyMceEditor from "src/_common/components/form-elements/tinyMceEditor";
import SwitchInput from "src/_common/components/form-elements/switchInput";

interface SettingsProps {
  slug: any;
  collection:any
}

interface TabLable {
  label: string;
  keyName: string;
}
interface SubTabnames extends TabLable {
  subGroup: boolean;
  parentKey?: string;
}
interface Tabnames extends TabLable {
  subTabs?: SubTabnames[];
}

const tabNames: Tabnames[] = [
  { label: 'Settings', keyName: 'storeHomePage' },
  { label: 'Social Media', keyName: 'storeSocialMedia' },
];

interface FormValues {
  id: number;
  key: string;
}

const SettingsForm = ({ slug, collection }: SettingsProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    register,
    formState: { errors },
  } = useForm<any>({});

  // const store = useStoreSelector();

  const collectionApi = useCollectionApi();
  const toast = useToaster();
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [activeTabName, setActiveTabName] = useState<any>('storeHomePage');
  const [activeSubTabName, setActiveSubTabName] = useState<string | null>(null);
  const [subTabs, setSubTabs] = useState<SubTabnames[]>([]);
  const [settings, setSettingsDeatils] = useState<any>();
  const [selectedImageValue, setSelectedImageValue] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [heroFileUrl, setHeroFileUrl] = useState<any>(null);
  const [selectedHeroImage, setSelectedHeroImage] = useState<any>(null);



  useEffect(() => {
    getSettingsDetails('storeHomePage')
  },[]);

  const getSettingsDetails = (keyName:any) => {
    const params:any = { 
    };

    if(keyName=='storeHomePage'){
      params['tabName']='branding'
    }
    else if (keyName=='storeSocialMedia'){
      params['tabName']='socials' 
    }

    collectionApi.callStoreDetails(
      params,
      (message: string, resp: any) => {
        console.log(resp)
        setSettingsDeatils(resp)
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const onSubmit = (data: FormValues) => {
    let params: any = { ...data };
    console.log('params', params)
    if(fileUrl){
      params['brandingLogo']= fileUrl;
    }

    if(heroFileUrl){
      params['brandingHeroImage']= heroFileUrl;
    }
    console.log('>>>>>>formData', params);
    settingsUpdate(params)
  };


  const settingsUpdate = (params:any) => {

    collectionApi.callUpdateSettings(
      params,
      (message: string, resp: any) => {
        console.log(resp)
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const onSelectFile = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let chooseImage = URL.createObjectURL(e.target.files[0]);
      setSelectedImage(chooseImage);
      // setDataLogo({ file, fileName: file.name, mimeType: file.type });
      let formData: any = new FormData();
      formData.append(
        'file',
        file
      );
      collectionApi.callUpdateFile(
        formData,
        (message: string, resp: any) => {
          if (resp) {
            console.log('select logo id', resp)
            // setLogoId(resp._id) 
            setFileUrl(resp?.fileUrl)
          } else {
            toast.error(message);
          }
        },
        (message: string) => {
          toast.error(message);
        }
      );
    }
  };

  const onSelectHeroFile = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let chooseImage = URL.createObjectURL(e.target.files[0]);
      setSelectedHeroImage(chooseImage);
      // setDataLogo({ file, fileName: file.name, mimeType: file.type });
      let formData: any = new FormData();
      formData.append(
        'file',
        file
      );
      collectionApi.callUpdateFile(
        formData,
        (message: string, resp: any) => {
          if (resp) {
            console.log('select logo id', resp)
            // setLogoId(resp._id) 
            setHeroFileUrl(resp?.fileUrl)
          } else {
            toast.error(message);
          }
        },
        (message: string) => {
          toast.error(message);
        }
      );
    }
  };

  const socialMediaLink = (e: React.MouseEvent, tab: string) => {
    console.log('tabs',tab )
    e.preventDefault();
    getSettingsDetails(tab)
    // getDetails(tab);
    setActiveTabName(tab);
    setActiveSubTabName(null);
    setSubTabs([]);
  };
  const subTabSelectHandler = (
    e: React.MouseEvent,
    subgroup: SubTabnames[],
    tab: string,
  ): void => {
    let tempSubTab: SubTabnames = subgroup[0];
    e.preventDefault();
    setActiveTabName(tab);

    setActiveSubTabName(tempSubTab.keyName);
    setSubTabs(subgroup);
    if (tempSubTab.subGroup) {
      console.log(tempSubTab.subGroup)
      // getDetails(tab, tempSubTab.keyName);
    } else {
      // getDetails(tab);
    }
  };

  const formValue = (item: any) => {
    if (item.fieldType === 'textarea') {
      return (
        <Controller
          defaultValue={item?.value || ''}
          control={control}
          name={item.key}
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTexAreatInput
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type="text"
              error={errors.description}
              placeholder={`Enter ${item.label}`}
              rows={4}
            />
          )}
        />
      );
    } else if (item.fieldType === 'input') {
      return (
        <Controller
          defaultValue={item?.value || ''}
          control={control}
          name={item.key}
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextInput
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type={item?.key === 'ethPrivateKey' ? 'password' : 'text'}
              error={errors.name}
              placeholder={`Enter ${item.label}`}
            />
          )}
        />
      );
    } else if (item.fieldType === 'file') {
      return (
        <div>
          <input
            // {...register('image')}
            type="file"
            className="form-control form-control-md"
            accept=".jpg, .jpeg, .png"
            onChange={item?.key=='brandingLogo' ? onSelectFile : onSelectHeroFile}
          />
          {item?.key=='brandingLogo' && (
          <div className="setting-image-preview-section">
            {item.value && !selectedImage ? (
              <img
                src={item.value}
                className="imageHeightShowing"
                width="100"
                alt="Picture of the author"
              />
            ) : selectedImage ? (
              <img
                src={selectedImage}
                className="imageHeightShowing"
                width="100"
                alt="Picture of the author"
              />
            ) : null}
          </div>
          )
          }

          {item?.key=='brandingHeroImage' && (
          <div className="setting-image-preview-section">
            {item.value && !selectedImage ? (
              <img
                src={item.value}
                className="imageHeightShowing"
                width="100"
                alt="Picture of the author"
              />
            ) : selectedHeroImage ? (
              <img
                src={selectedHeroImage}
                className="imageHeightShowing"
                width="100"
                alt="Picture of the author"
              />
            ) : null}
          </div>
          )
          }
        </div>
      );
    } else if (item.fieldType === 'dropdown') {
      return (
        <Controller
          defaultValue={
            item?.fieldOptions?.options?.length
              ? item.fieldOptions.options.find(
                  (tempVal: any) => tempVal?.value === item.value,
                )
              : ''
          }
          control={control}
          name={item.key}
          render={({ onChange, onBlur, value, name, ref }) => (
            <SelectInput
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              options={item?.fieldOptions?.options}
              error={errors.name}
              placeholder={`Enter ${item.label}`}
            />
          )}
        />
      );
    } else if (item.fieldType === 'password') {
      return (
        <Controller
          defaultValue={item?.value || ''}
          control={control}
          name={item.key}
          render={({ onChange, onBlur, value, name, ref }) => (            
          <FormTextInput
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type="password"
              error={errors.name}
              placeholder={`Enter ${item.label}`}
            />
          )}
        />
      );
    } else if (item.fieldType === 'editor') {
      return (
        <div className="border border-1">
          <Controller
            defaultValue={item?.value || ''}
            control={control}
            name={item.key}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TinyMceEditor
                name={name}
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                inline={false}
                showSupportText={false}
              />
            )}
          />
        </div>
      );
    } else if (item.fieldType === 'radio') {
      return (
        <div className="mt-3">
          <Controller
            defaultValue={item?.value || false}
            control={control}
            name={item.key}
            render={({ onChange, onBlur, value, name, ref }) => (
              <SwitchInput
                onChange={onChange}
                name={name}
                value={value}
                inputRef={ref}
                id={name}
                label={item.label}
              />
            )}
          />
          {/* {item.key === 'sitemapHtml' && (
            <a
              className="d-block"
              target="_blank"
              href={'https://' + store?.domain + '/sitemap.html'}
            >
              sitemap.html
            </a>
          )}
          {item.key === 'sitemapXml' && (
            <a
              className="d-block"
              target="_blank"
              href={'https://' + store?.domain + '/sitemap.xml'}
            >
              sitemap.xml
            </a>
          )} */}
        </div>
      );
    }
  };

  const showSubTableHandler = (): JSX.Element => {
    if (subTabs && subTabs.length) {
      return (
        <ul className="nav nav-tabs nav-new ">
          {subTabs.map((tab) => (
            <li className="nav-item" key={tab.keyName}>
              <a
                className={`nav-link tabFontSize ${
                  activeSubTabName === tab.keyName && 'active'
                }`}
                aria-current="page"
                href="#"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  setActiveSubTabName(tab.keyName);
                  // getDetails(
                  //   String(tab.parentKey ? tab.parentKey : ''),
                  //   tab.subGroup ? tab.keyName : null,
                  // );
                }}
              >
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
      );
    } else {
      return <></>;
    }
  };

  return (
    <section className="inner-section-wrap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="information get_start tab-sec-n settings-custom-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18 mb-3">Site Settings</h4>
                  </div>
                  <ul className="nav nav-tabs nav-new ">
                    {tabNames.map((tab) => (
                      <li className="nav-item" key={tab.keyName}>
                        <a
                          className={`nav-link tabFontSize ${
                            activeTabName === tab.keyName && 'active'
                          }`}
                          aria-current="page"
                          href="#"
                          onClick={(e: React.MouseEvent) => {
                            // if (tab?.subTabs?.length) {
                            //   subTabSelectHandler(e, tab.subTabs, tab.keyName);
                            // } else {
                              socialMediaLink(e, tab.keyName);
                            // }
                          }}
                        >
                          {tab.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-12">{showSubTableHandler()}</div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-sm-12 col-12">
                    <div className="login-text ">
                      {settings && settings.length
                        ? settings.map((item: any, index: number) => (
                            <div className="form-group" key={item._id}>
                              {item.fieldType !== 'radio' && (
                                <label>{item.label}</label>
                              )}
                              {formValue(item)}
                            </div>
                          ))
                        : 
                        (
                          <div>
                          <p>Please wait.....</p>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="login-text col-sm-12 d-flex justify-content-center">
                    {settings &&
                    settings?.length != 0 ? (
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SettingsForm;
